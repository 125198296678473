import { Component, ElementRef, OnInit } from '@angular/core';
import * as moment from 'moment';
import { PageBase, RentalInfo, RentalStatus, TurboHubEventType } from 't4core';
import { AppIntegrationService } from 't4-app-integration';
import { NavigationService } from 'app-components';
import { ActiveRentalService } from 'app-components';
import { ExtendRentalComponent } from '../../views/extend-rental/extend-rental.component';
import { LocationInfoComponent } from '../../dialogs/location-info/location-info.component';
import { TrailerInfoComponent } from '../../dialogs/trailer-info/trailer-info.component';
import { LayoutService } from 'app-components';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-in-flight',
    templateUrl: './in-flight.component.html',
    styleUrls: ['./in-flight.component.css']
})
export class InFlightComponent extends PageBase implements OnInit {

    public booking: RentalInfo;
    public countdown: moment.Duration;
    public late: boolean = false;
    public hasLock: boolean = false;

    public lockOpen: boolean = false;

  constructor(el: ElementRef, private aviationService: NavigationService, private appSvc: AppIntegrationService, private rentalService: ActiveRentalService, private layoutService: LayoutService) {
    super(el);
  }

  private notificationSub: Subscription;
    async ngOnInit() {
      this.booking = await this.rentalService.getBooking();
      this.init();

      ////If we extend, we update the cached rental Object to the correct Time.
      //if (this.aviationService.getParam('extended') && this.aviationService.getParam('rentalId')){
      //  this.rentalService.setActiveRental(parseInt(this.aviationService.getParam('rentalId')))
      //}

      this.notificationSub = this.rentalService.onRentalUpdated.pipe(filter(x => x == this.booking.Id)).subscribe(async x => {
        this.booking = await this.rentalService.getBooking();

        this.init();
      });
  }

  async ngOnDestroy() {
    if (this.notificationSub) this.notificationSub.unsubscribe();
  }

  private async init() {
    if (this.booking.Status < RentalStatus.Active) this.aviationService.executeCommand("ReversedRemotely");
    if (this.booking.Status > RentalStatus.Late) this.aviationService.executeCommand("ReturnedRemotely");

      this.hasLock = await this.rentalService.hasLock();
      this.late = this.booking.Status == RentalStatus.Late;
  }

    secondTick() {
        if (this.booking) {
            this.countdown = moment.duration(this.booking.ReturnTime.diff(moment()));
            if (this.countdown.asSeconds() <= 0) {
              this.late = true;
            }
        }
    }

  public recieveRentalAction(action: string):void {
    switch (action) {
      case "unlock": this.unlock();
      case "extend": this.extend();
      case "return": this.return();
    }
  }

  public unlock(): void {
    this.aviationService.executeCommand("Unlock", { event: "Active" });
  }
  public extend(): void {
    this.aviationService.executeCommand("Extend", { rentalId: this.booking.Id });
  }
  public return(): void {
    this.aviationService.executeCommand("Return", { event: "Return" });
  }

    public openDialog(): void {
        const dialogRef = this.Dialog.open(ExtendRentalComponent, {
            width: '350px',
            data: { bookingId: this.booking.Id, typeId: this.booking.PrimaryType.Id, locationId: this.booking.ReturnLocationId, pickupTime: this.booking.PickupTime, returnTime: this.booking.ReturnTime, selected: null }
        });

        dialogRef.afterClosed().subscribe(async result => {
            console.log('The dialog was closed');
            if (result) {
                this.booking = await this.rentalService.getBooking();
            }
        });
    }

    public openLocationInfoDialog(): void {
        const dialogRef = this.Dialog.open(LocationInfoComponent, {
            width: '350px',
            data:  this.booking.PickupLocation.Id
        });

        dialogRef.afterClosed().subscribe(async result => {
            console.log('The dialog was closed');
        });
    }

    public openTrailerInfoDialog(): void {
        const dialogRef = this.Dialog.open(TrailerInfoComponent, {
            width: '350px',
            data: this.booking.ObjectId
        });

        dialogRef.afterClosed().subscribe(async result => {
            console.log('The dialog was closed');
        });
    }
}
