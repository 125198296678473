import { Component, OnInit, ElementRef } from '@angular/core';
import { PageBase, PriceModel, RentalInfo, RentalStatus } from 't4core';
import { NavigationService } from 'app-components';
import * as moment from 'moment';
import { LayoutService } from 'app-components';
import { AppSettingsService } from 't4-app-integration';

@Component({
  selector: 'app-return-complete',
  templateUrl: './return-complete.component.html'
})
export class ReturnCompleteComponent extends PageBase implements OnInit {
  public theme: string;
  constructor(el: ElementRef, private aviationService: NavigationService, public appSettings: AppSettingsService) {
    super(el);
  }

  async ngOnInit() {
    this.theme = this.appSettings.settings.theme || 'Default';
  }


  public confirm() {
    this.aviationService.executeCommand("TakeMeHome");
  }
}
