import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BookingModel, BookingService, LayoutService, NavigationResolver, NavigationService, ActiveRentalService } from 'app-components';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { InsightsService, PageBase, RentalInfo, RentalStatus, TurboHubEventType } from 't4core';

@Component({
  selector: 'app-booking-info',
  templateUrl: './booking-info.component.html',
  styleUrls: ['./booking-info.component.css']
})
export class BookingInfoComponent extends PageBase implements OnInit, OnDestroy {

  public booking: RentalInfo;
  public toEarly: boolean = false;
  public toFarAway: boolean = false;

  public countdown: moment.Duration;
  public logoImageBegin: string = "https://thuleturbo.blob.core.windows.net/locationgrouppictures/3_";
  public logoImageEnd: string = ".png?g=";

  public isReturn: boolean = false;
  public firstLoad = true;
  public firstLoadLoader = false;
  public updateInProgress = false;
  public updateInProgressWait = false;
  public locationTries: number = 0;
  public hasLock: boolean = false;

  constructor(el: ElementRef, public appService: AppIntegrationService, private navigation: NavigationService, private rentalService: ActiveRentalService, public layoutService: LayoutService, public appSettings: AppSettingsService, private resolver: NavigationResolver, private insights: InsightsService, private bookingDataService: BookingService) {
    super(el);
  }

  private notificationSub: Subscription;
  async ngOnInit() {

      var token = this.UI.beginLoading("Intellitrailer.Rental.BookingInfo.msgLoadingOptions", "Loading booking data", null);
    await this.init();

    if (this.booking && this.booking.Id) {

      var appStore = this.appSettings.clientVersion + " (" + this.appSettings.appVersion ? this.appSettings.appVersion : "" + ")";
      var info = "Opened booking. Device info: " + appStore + ". " + navigator.userAgent;
      this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: info });

      var info = 'Status: ' + this.booking.Status + '. Lock: ' + this.hasLock;
      this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: info });

    }

    this.UI.loaderCompleted(token);

    this.notificationSub = this.Notifications.onEvent.pipe(filter(x => x.Type == TurboHubEventType.Booking && x.EntityId == this.booking.Id.toString())).subscribe(async x => {
      this.booking = await this.rentalService.getBooking();

      this.init();
    });
  }

  async ngOnDestroy() {
    if (this.notificationSub) this.notificationSub.unsubscribe();
  }

  private async init() {
    this.booking = await this.rentalService.getBooking();
    if (this.booking.Status >= RentalStatus.Active) this.navigation.executeCommand("StartedRemotely");

    //Check if object has lock
    this.hasLock = await this.rentalService.hasLock();

    this.isReturn = (this.booking && (this.booking.Status == RentalStatus.Active || this.booking.Status == RentalStatus.Late));
  }

  recieveRentalAction(action: string) {
    eval('this.' + action + '();')
  }

  async secondTick() {
  }

  async minuteTick() {
  }
  public async start() {
    //Check if user is to far away to start/return trailer
    var distance = await this.appService.getDistanceFromUser(this.booking.PickupLocation.TrailerPointLatitude, this.booking.PickupLocation.TrailerPointLongitude) * 1000;
    this.toFarAway = distance > this.booking.PickupLocation.TrailerPointRadius + 1000;

    //Override for demo (Set user as at location)
    if (localStorage.getItem('atLocation')) {
      this.toFarAway = localStorage.getItem('atLocation') == "true" ? false : this.toFarAway;
    }

    this.navigation.executeCommand("Start");
  }

  public async cancelBooking() {
    var c = await this.rentalService.cancel();
    if (c) {
      this.navigation.executeCommand("Canceled");
    }
  }

  public async changeRentalTimes() {
    var cleared = await this.bookingDataService.startSession();
    if (cleared) {
    var bookingState: BookingModel = {
      booking: this.booking,
      freshLoad: true,
      timeSteps: "quarter",
      session: moment().milliseconds(),
      existingBooking: this.booking.Id + "",
      objectCategories: [],
    };
    this.bookingDataService.saveState(bookingState);
      this.navigation.executeCommand("ChangeRentalTimes", { event: "ChangeRentalTime", rentalId: this.booking.Id });
    }
  }

  public openExternal(url: string) {
    this.appService.openExternal(url);
  }

  public back() {
    this.navigation.back();
  }

}
