import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { ApiService, CacheService, SettingsService, T4coreModule } from 't4core';

import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeNb from '@angular/common/locales/nb';
import localeNn from '@angular/common/locales/nn';
import localeSv from '@angular/common/locales/sv';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AppComponentsModule, NavigationService, NavigationStep } from 'app-components';
import { AppOnewayModule, OWAlternativeSelectorComponent, OWModelSelectorComponent } from 'app-oneway';
import { AppSettings, AppSettingsService } from 't4-app-integration';
import { BookingOptionComponent } from '../lib/Components/booking-option/booking-option.component';
import { PhoneFieldComponent } from '../lib/Components/form-phone-field/phone-field.component';
import { TextFieldComponent } from '../lib/Components/form-text-field/text-field.component';
import { InformationScreenComponent } from '../lib/Components/information-screen/information-screen.component';
import { LoadingScreenComponent } from '../lib/Components/loading-screen/loading-screen.component';
import { LocationMapComponent } from '../lib/Components/location-map/location-map.component';
import { LocationInformation } from '../lib/Components/LocationInformation/location-information.component';
import { MenuComponent } from '../lib/Components/menu/menu.component';
import { NewUserInfoComponent } from '../lib/Components/new-user-info/new-user-info.component';
import { ObjectInfoComponent } from '../lib/Components/object-info/object-info.component';
import { OnLoadComponent } from '../lib/Components/on-load/on-load.component';
import { PlaceSearchComponent } from '../lib/Components/place-search/place-search';
import { TimePicker } from '../lib/Components/timePicker/timePicker';
import { AppComponent } from './app.component';
import { BookingSummaryComponent } from './Booking/Components/booking-summary/booking-summary.component';
import { CategoryComponent } from './Booking/Components/category/category.component';
import { CustomerFormComponent } from './Booking/Components/customer-form/customer-form.component';
import { LocationComponent } from './Booking/Components/location/location.component';
import { CategorySelector } from './Booking/Components/trailer-categories/category-selector';
import { CreateBookingComponent } from './Booking/create-booking/create-booking.component';
import { AddonPopupComponent } from './Booking/dialogs/addon-popup/addon-popup.component';
import { FindLocationComponent } from './Booking/dialogs/find-location/find-location.component';
import { RentalAgreementComponent } from './Booking/dialogs/rental-agreement/rental-agreement.component';
import { ConfirmedBookingComponent } from './Booking/views/confirmed-booking/confirmed-booking.component';
import { DateAndTime } from './Booking/views/date-and-time/date-and-time';
import { InitiateNewBookigComponent } from './Booking/views/initiate-new-bookig/initiate-new-bookig.component';
import { PaymentComponent } from './Booking/views/payment/payment.component';
import { PickupLocationComponent } from './Booking/views/pickup-location/pickup-location';
import { ReturnLocationComponent } from './Booking/views/return-location/return-location';
import { SelectCategoryComponent } from './Booking/views/select-category/select-category.component';
import { SelectObject } from './Booking/views/select-object/select-object.component';
import { SetupCustomerComponent } from './Booking/views/setup-customer/setup-customer.component';
import { UnconfirmedBookingComponent } from './Booking/views/unconfirmed-booking/unconfirmed-booking.component';
import { HomeComponent } from './Home/Components/home/home.component';
import { OneWayHowToComponent } from './Home/Components/OneWayHowTo/OneWayHowTo.component';
import { OneWayStartComponent } from './Home/Components/OneWayStart/OneWayStart.component';
import { MonitorInterceptor } from './monitor.interceptor';
import { BookingOverviewComponent } from './MyPage/Components/booking-overview/booking-overview.component';
import { VerifyAccountComponent } from './MyPage/Components/verify-account/verify-account.component';
import { GPSDialogComponent } from './MyPage/Dialogs/gps-dialog/gps-dialog.component';
import { ImportBookingComponent } from './MyPage/Dialogs/import-booking/import-booking.component';
import { PinCodeDialog } from './MyPage/Dialogs/pin-code-dialog/pin-code-dialog';
import { MyBookingsComponent } from './MyPage/Views/my-bookings/my-bookings.component';
import { SettingsComponent } from './MyPage/Views/settings/settings.component';
import { SignInComponent } from './MyPage/Views/sign-in/sign-in.component';
import { PhotoViewComponent } from './Rental/Components/photo-view/photo-view.component';
import { RentalBoxComponent } from './Rental/Components/rental-box/rental-box.component';
import { LocationInfoComponent } from './Rental/dialogs/location-info/location-info.component';
import { TrailerInfoComponent } from './Rental/dialogs/trailer-info/trailer-info.component';
import { BookingInfoComponent } from './Rental/Views/booking-info/booking-info.component';
import { BookingUpdatedComponent } from './Rental/Views/booking-updated/booking-updated.component';
import { ChangeObjectComponent } from './Rental/Views/change-object/change-object.component';
import { ConfirmLockComponent } from './Rental/Views/confirm-lock/confirm-lock.component';
import { DetectLockComponent } from './Rental/Views/detect-lock/detect-lock.component';
import { DiscloseBookingComponent } from './Rental/Views/disclose-booking/disclose-booking.component';
import { ExtendRentalComponent } from './Rental/views/extend-rental/extend-rental.component';
import { InFlightComponent } from './Rental/Views/in-flight/in-flight.component';
import { LockTrailerComponent } from './Rental/Views/lock-trailer/lock-trailer.component';
import { PickupCompleteComponent } from './Rental/Views/pickup-complete/pickup-complete.component';
import { ReturnCompleteComponent } from './Rental/Views/return-complete/return-complete.component';
import { ReturnInfoComponent } from './Rental/Views/return-info/return-info.component';
import { ReturnPaymentComponent } from './Rental/Views/return-payment/return-payment.component';
import { SecurityCheckComponent } from './Rental/Views/security-check/security-check.component';
import { StartRentalComponent } from './Rental/Views/start-rental/start-rental.component';
import { StopRentalComponent } from './Rental/Views/stop-rental/stop-rental';
import { StowLockComponent } from './Rental/Views/stow-lock/stow-lock.component';
import { TakePicturesComponent } from './Rental/Views/take-pictures/take-pictures.component';
import { UnlockAfterCheckComponent } from './Rental/Views/unlock-after-check/unlock-after-check.component';
import { Use4FreeComponent } from './Home/Components/Use4Free/Use4Free';
import { ViewContentComponent } from '../lib/Components/view-content/view-content';
import { MatStepperModule } from '@angular/material/stepper';
import { ViewActionsComponent } from '../lib/Components/view-actions/view-actions';
import { AppCardComponent } from '../lib/Components/app-card/app-card';
import { BTPermissionRequiredComponent } from '../lib/InformationScreens/btPermissionRequired/btPermissionRequired';
import { LocationPermissionRequiredComponent } from '../lib/InformationScreens/locationPermissionRequired/locationPermissionRequired';
import { CameraPermissionRequired } from '../lib/InformationScreens/cameraPermissionRequired/cameraPermissionRequired';
import { KeepInMindComponent } from './Rental/Views/keep-in-mind/keep-In-mind.component';
import { SelectFamilyType } from './Booking/views/select-family-type/select-family-type';
import { AskPurchaseComponent } from './Rental/Views/ask-purchase/ask-purchase';
import { ExtendRentalTimePickerComponent } from './Rental/Views/extend-rental-timePicker/extend-rental-timePicker';
import { SelectedObjectInfo } from './Booking/views/selected-object-info/selected-object-info';
import { ChatbotComponent } from '../lib/Components/chatbot/chatbot.component';
import { IdleDialog } from './MyPage/Dialogs/idle-dialog/idle-dialog';
import { NokeLockComponent } from './Rental/Views/noke-lock/noke-lock.component';
import { IglooLockComponent } from './Rental/Views/igloo-lock/igloo-lock.component';
import { NFCNoLockComponent } from './Rental/Views/nfc-nolock/nfc-nolock.component';
import { IdleTimerComponent } from '../lib/Components/idle-timer/idle-timer.component';


// Routes
export const NavigationSteps: NavigationStep[] = [
  {
    Key: "Loading",
    Path: '**',
    AllowColdStart: true,
    Component: OnLoadComponent,
    Actions: []
  },
];

export const components: { [key: string]: any } = {
  AppComponent: AppComponent,
  HomeComponent: HomeComponent,
  BookingInfoComponent: BookingInfoComponent,
  DetectLockComponent: DetectLockComponent,
  StowLockComponent: StowLockComponent,
  CategorySelector: CategorySelector,
  TimePicker: TimePicker,
  DateAndTime: DateAndTime,
  LocationInformation: LocationInformation,
  SelectObject: SelectObject,
  SelectedObjectInfo: SelectedObjectInfo,
  SelectFamilyType: SelectFamilyType,
  ObjectInfoComponent: ObjectInfoComponent,
  InformationScreenComponent: InformationScreenComponent,
  SecurityCheckComponent: SecurityCheckComponent,
  TakePicturesComponent: TakePicturesComponent,
  BookingOptionComponent: BookingOptionComponent,
  SetupCustomerComponent: SetupCustomerComponent,
  UnconfirmedBookingComponent: UnconfirmedBookingComponent,
  BookingSummaryComponent: BookingSummaryComponent,
  PhotoViewComponent: PhotoViewComponent,
  PickupCompleteComponent: PickupCompleteComponent,
  InFlightComponent: InFlightComponent,
  LockTrailerComponent: LockTrailerComponent,
  ConfirmLockComponent: ConfirmLockComponent,
  ReturnCompleteComponent: ReturnCompleteComponent,
  ConfirmedBookingComponent: ConfirmedBookingComponent,
  PaymentComponent: PaymentComponent,
  BookingOverviewComponent: BookingOverviewComponent,
  FindLocationComponent: FindLocationComponent,
  ChangeObjectComponent: ChangeObjectComponent,
  ExtendRentalComponent: ExtendRentalComponent,
  ExtendRentalTimePickerComponent: ExtendRentalTimePickerComponent,
  MenuComponent: MenuComponent,
  SettingsComponent: SettingsComponent,
  LocationInfoComponent: LocationInfoComponent,
  TrailerInfoComponent: TrailerInfoComponent,
  VerifyAccountComponent: VerifyAccountComponent,
  NewUserInfoComponent: NewUserInfoComponent,
  SelectCategoryComponent: SelectCategoryComponent,
  RentalAgreementComponent: RentalAgreementComponent,
  AddonPopupComponent: AddonPopupComponent,
  OnLoadComponent: OnLoadComponent,
  ImportBookingComponent: ImportBookingComponent,
  IdleDialog: IdleDialog,
  ReturnInfoComponent: ReturnInfoComponent,
  GPSDialogComponent: GPSDialogComponent,
  UnlockAfterCheckComponent: UnlockAfterCheckComponent,
  InitiateNewBookigComponent: InitiateNewBookigComponent,
  DiscloseBookingComponent: DiscloseBookingComponent,
  SignInComponent: SignInComponent,
  BookingUpdatedComponent: BookingUpdatedComponent,
  MyBookingsComponent: MyBookingsComponent,
  CreateBookingComponent: CreateBookingComponent,
  StartRentalComponent: StartRentalComponent,
  StopRentalComponent: StopRentalComponent,
  ReturnPaymentComponent: ReturnPaymentComponent,
  ReturnLocationComponent: ReturnLocationComponent,
  PickupLocationComponent: PickupLocationComponent,
  KeepInMindComponent: KeepInMindComponent,

  OneWayStartComponent: OneWayStartComponent,
  OneWayHowToComponent: OneWayHowToComponent,
  OWModelSelectorComponent: OWModelSelectorComponent,
  OWAlternativeSelectorComponent: OWAlternativeSelectorComponent,
  Use4FreeComponent: Use4FreeComponent,
  AskPurchaseComponent: AskPurchaseComponent,
  ChatbotComponent: ChatbotComponent,
  NokeLockComponent: NokeLockComponent,
  IglooLockComponent: IglooLockComponent,
  NFCNoLockComponent: NFCNoLockComponent
}

// App initialization
export function initApp(settingsService: SettingsService, appSettings: AppSettingsService, api: ApiService, cache: CacheService) {
  return () => { 
    settingsService.appInsightsKey = '154faa6b-f843-4050-9c4f-d27ca633355f';
    settingsService.appName = "T4.App";
    settingsService.appVersion = window["appVersion"] + " - 4.0";

    api.getVersion();
    
    //settingsService.setApiBase("https://api.turbo4.net/");
    //settingsService.ApiBase = "https://api.t4.brenderuprental.com/";
   // settingsService.setApiBase("https://api.t4.brenderuprental.com/");
    //settingsService.setApiBase("https://localhost:44310/");
    //settingsService.setApiBase("https://api-test.turbo4.net/");

    if (isDevMode()) {
      //settingsService.setApiBase("https://localhost:44310/"); 
    }

    // Retrieve parameters
    appSettings.initParams();



    settingsService.appVersion = window["appVersion"] + " - " + appSettings.appVersion;



    // Configure cache
    cache.context = appSettings.contextId;

    AppSettingsService.getComponents(components);

    api.setCancelOnNavigation(false);
    return true;
  };
}
// App initialization

// Register languages
registerLocaleData(localeSv);
registerLocaleData(localeDa);
registerLocaleData(localeNn);
registerLocaleData(localeNb);
registerLocaleData(localeDe);

@NgModule({
  declarations: [
    LoadingScreenComponent,
    AppComponent,
    HomeComponent,
    BookingInfoComponent,
    DetectLockComponent,
    StowLockComponent,
    CategorySelector,
    TimePicker,
    DateAndTime,
    LocationInformation,
    BookingInfoComponent,
    SelectObject,
    SelectedObjectInfo,
    SelectFamilyType,
    ObjectInfoComponent,
    InformationScreenComponent,
    SecurityCheckComponent,
    TakePicturesComponent,
    BookingOptionComponent,
    SetupCustomerComponent,
    UnconfirmedBookingComponent,
    BookingSummaryComponent,
    PhotoViewComponent,
    PickupCompleteComponent,
    InFlightComponent,
    LockTrailerComponent,
    ConfirmLockComponent,
    ReturnCompleteComponent,
    ConfirmedBookingComponent,
    PaymentComponent,
    BookingOverviewComponent,
    FindLocationComponent,
    ChangeObjectComponent,
    ExtendRentalComponent,
    ExtendRentalTimePickerComponent,
    MenuComponent,
    SettingsComponent,
    LocationInfoComponent,
    TrailerInfoComponent,
    VerifyAccountComponent,
    NewUserInfoComponent,
    SelectCategoryComponent,
    RentalAgreementComponent,
    AddonPopupComponent,
    OnLoadComponent,
    ImportBookingComponent,
    IdleDialog,
    ReturnInfoComponent,
    GPSDialogComponent,
    PhoneFieldComponent,
    TextFieldComponent,
    UnlockAfterCheckComponent,
    MyBookingsComponent,
    CategoryComponent,
    LocationComponent,
    InitiateNewBookigComponent,
    DiscloseBookingComponent,
    CreateBookingComponent,
    SignInComponent,
    RentalBoxComponent,
    BookingUpdatedComponent,
    PlaceSearchComponent,
    StartRentalComponent,
    StopRentalComponent,
    PinCodeDialog,
    ReturnPaymentComponent,
    CustomerFormComponent,
    ReturnLocationComponent,
    LocationMapComponent,
    PickupLocationComponent,

    BTPermissionRequiredComponent,
    LocationPermissionRequiredComponent,
    CameraPermissionRequired,

    OneWayStartComponent,
    OneWayHowToComponent,
    Use4FreeComponent,
    ViewContentComponent,
    ViewActionsComponent,
    AppCardComponent,
    KeepInMindComponent,
    AskPurchaseComponent,
    ChatbotComponent,
    NokeLockComponent,
    IglooLockComponent,
    NFCNoLockComponent,
    IdleTimerComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    T4coreModule,
    AppComponentsModule,
    AppOnewayModule,
    MatStepperModule,

    RouterModule.forRoot(NavigationService.getRoutes(NavigationSteps), {
    scrollPositionRestoration: 'top'
}),
    BrowserAnimationsModule,
    GoogleMapsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatTabsModule, 
    MatProgressSpinnerModule,
    MatMenuModule,
    MatDialogModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSelectModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF, useFactory: () => { return window['baseUri'] ?? '/'; } },
    NavigationService,
    T4coreModule,
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [SettingsService, AppSettingsService, ApiService, CacheService], multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: HTTP_INTERCEPTORS, useClass: MonitorInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useFactory: (settings: AppSettingsService) => {

        var language = settings.getLanguage();
        if (language == "no" || language == "nb") return "nn";
        return language;

      },
      deps: [AppSettingsService]
    }
  ],
  exports: [T4coreModule, MatSlideToggleModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, MatIconModule, MatTooltipModule, MatTabsModule, MatMenuModule, MatDialogModule, MatRadioModule, MatExpansionModule, MatSelectModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
