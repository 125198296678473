import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { PageBase, RentalInfo, RentalStatus } from 't4core';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { NavigationResolver, NavigationService, ActiveRentalService, LayoutService } from 'app-components';

@Component({
    selector: 'app-detect-lock',
    templateUrl: './detect-lock.component.html',
    styleUrls: ['./detect-lock.component.css']
})

export class DetectLockComponent extends PageBase implements OnInit {
      
  public booking: RentalInfo = new RentalInfo();
  public event: string = ""; // Pickup/Return/Active etc.
  constructor(el: ElementRef, public appSvc: AppIntegrationService, private aviationService: NavigationService, private rentalService: ActiveRentalService, public layoutService: LayoutService, public resolver: NavigationResolver, public appSettings: AppSettingsService) {
    super(el);
    this.event = this.aviationService.getParam("event");
  }

  async ngOnInit() {
    this.booking = await this.rentalService.getBooking();
    var lockType = this.booking.RentalObject.Locks.map(x => x.Type)[0];

    if (lockType == "Igloo-V1") {
      this.aviationService.executeCommand("Igloo");
    }
    else if (lockType == "NFC") {
      this.aviationService.executeCommand("NFC");
    }
    else {
      this.aviationService.executeCommand("Noke");
    }
  }
}
