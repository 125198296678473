import { SlicePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { MapMarker } from '@angular/google-maps';
import { BookingModel, BookingService, NavigationService } from 'app-components';
import { AppIntegrationService, AppSettingsService, Coordinates } from 't4-app-integration';
import { ComponentBase, LocationInfo, TranslationService } from 't4core';
import { IdleDialog } from '../../../app/MyPage/Dialogs/idle-dialog/idle-dialog';

export enum MapMode {
  Local = 'Local',
  Pickup = 'Pickup',
  Return = 'Return'
}
 
@Component({
  selector: 'location-map',
  templateUrl: 'location-map.html'
})
export class LocationMapComponent extends ComponentBase implements OnInit, OnChanges, OnDestroy {
  @Input() public locations: LocationInfo[] = [];
  @Input() public selectedLocation: LocationInfo;
  @Input() public selectedPickupLocation: LocationInfo;

  @Input() public mode: MapMode = MapMode.Local;

  @Output() public selectedLocationChange: EventEmitter<LocationInfo> = new EventEmitter<LocationInfo>();
  @Output() public locationConfirmed: EventEmitter<LocationInfo> = new EventEmitter<LocationInfo>(); 


  public showSearch: boolean = false;
  public selectedDistance: number = 0;
  public selectedDuration: number = 0;
  public selectedDurationHours: number = 0;
  public selectedDurationMinutes: number = 0;

  public bookingState: BookingModel;


  //Google maps
  @ViewChild('gmap', { static: true }) gmapElement: ElementRef;

  private directions: google.maps.DirectionsService;
  private directionsRenderer: google.maps.DirectionsRenderer;

  private map: google.maps.Map;
  private markers = [];
  private searchPin: google.maps.Marker;
  private infoWindow: google.maps.InfoWindow;
  private zoomDistances = [
    { zoom: 17, maxDistance: 0.5 },
    { zoom: 16, maxDistance: 1 },
    { zoom: 14, maxDistance: 2 },
    { zoom: 12, maxDistance: 4 },
    { zoom: 10, maxDistance: 8 },
    { zoom: 9, maxDistance: 16 },
    { zoom: 8, maxDistance: 32 },
    { zoom: 7, maxDistance: 64 },
    { zoom: 6, maxDistance: 128 },
    { zoom: 5, maxDistance: 256 },
    { zoom: 4, maxDistance: 512 },
    { zoom: 3, maxDistance: 1024 },
    { zoom: 2, maxDistance: 2048 },
    { zoom: 1, maxDistance: 4096 }
  ];

  private searchIsDirty: boolean = false;


  constructor(
    private navigator: NavigationService,
    el: ElementRef,
    private zone: NgZone,
    private appSettings: AppSettingsService,
    private aviationService: NavigationService,
    private translationsService: TranslationService,
    private appService: AppIntegrationService,
    private bookingService: BookingService) {
    super();

    this.bookingState = this.bookingService.getBookingState();
  }

  public async ngOnDestroy() {
    this.markers.map(x => {
      if (x.marker) x.marker.setMap(null);
      if (x.circle) x.circle.setMap(null);
    });
    if (this.searchPin) this.searchPin.setMap(null);
    if (this.directionsRenderer) this.directionsRenderer.setMap(null);

    window['bookFromMap'] = undefined;

    this.map.unbindAll();
    this.map = null;

    super.ngOnDestroy();
  }

  public async ngOnInit() {
    const { } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;

    this.bookingState = await this.bookingService.getBookingState();

    // Add a js function to enable events from button in map tooltip
    window['bookFromMap'] = () => {
      this.zone.run(() => {
        this.locationConfirmed.emit(this.selectedLocation);
      });
    };

    var latlng = new google.maps.LatLng(
      this.Settings.clientSettings.DefaultLatitude,
      this.Settings.clientSettings.DefaultLongitude
    ); // This sets the default location, before user location is found

    var mapProp = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      key: "AIzaSyBEjGnurCxh3ZDYNjyxQDlaCZXQxxvM0-E",
      disableDefaultUI: true,
      mapId: '17c55b6aa3c322c5',
      zoom: 10,
      center: latlng
    };
    if (this.gmapElement && this.gmapElement.nativeElement) {
      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

      this.map.setCenter({ lat: this.Settings.clientSettings.DefaultLatitude, lng: this.Settings.clientSettings.DefaultLongitude });
      this.map.setZoom(5);

      this.map.addListener("click", (event: any) => {
        if (this.infoWindow)
          this.infoWindow.close();

        // If the event has a placeId, use it.
        if (event.placeId) {
          event.stop();
        }
      });

      if (this.locations) {
        this.updateMarkers();

        var coords = await this.appService.getCoordinates();
        if (coords) {
          this.gotoPlace(coords);
        }
      }
    }
  }

  public firstChangeDone : boolean = false;

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.map) return;
    this.updateMarkers();

    //Catches the first change, and if a pin already exist.
    //Resolves the cold loading into the map, and the pin issues when the pin temporarly sets to closet station.
    if (!this.firstChangeDone && this.searchPin) {
      this.firstChangeDone = true;
      return;
    }

    if ((changes["locations"] && !changes["locations"].previousValue && changes["locations"].currentValue)) {
      if (this.selectedLocation) {
        this.gotoPlace({ Latitude: this.selectedLocation.Latitude, Longitude: this.selectedLocation.Longitude });
      } else {
        var coords = await this.appService.getCoordinates();
        if (coords) this.gotoPlace(coords);
      }
    } else if ((changes["selectedLocation"] && !changes["selectedLocation"].previousValue && changes["selectedLocation"].currentValue)) {
      this.goneToPlace = false;
      if (this.selectedLocation) this.gotoPlace({ Latitude: this.selectedLocation.Latitude, Longitude: this.selectedLocation.Longitude });
    } 
  }

  private _tickCounter: number = 0;
  public async secondTick() {
    if (this._isDestroyed) return;

    this._tickCounter++;
    if (!this.searchIsDirty && this._tickCounter == 15) {
      var coords = await this.appService.getCoordinates(false);
      if (!coords || this._isDestroyed) return;

      if(this.searchPin) this.searchPin.setPosition({ lat: coords.Latitude, lng: coords.Longitude });
      this._tickCounter = 0;
    }
  }

  public toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private goneToPlace: boolean = false;

  public async search(coords: Coordinates) {
    this.searchIsDirty = true;
    this.goneToPlace = false;
    await this.gotoPlace(coords);
  }

  public setPin(coords: Coordinates) {
    // Remove existing pin
    if (this.searchPin) this.searchPin.setMap(null);

    // Add pin
    this.searchPin = new google.maps.Marker({
      map: this.map,
      position: { lat: coords.Latitude, lng: coords.Longitude },
      animation: google.maps.Animation.DROP,
      zIndex: 2001,
      clickable: false
    });
    this.searchPin.setZIndex(175);
  }

  public async gotoPlace(coords: Coordinates, adaptiveZoom: boolean = false) {
    if (this._isDestroyed || !this.map) return;

    // Verify place
    if (!coords && coords.Latitude && coords.Longitude) return;
    if (coords.Longitude == this.Settings.clientSettings.DefaultLongitude && coords.Latitude == this.Settings.clientSettings.DefaultLatitude) return;

    this.setPin(coords);

    if (this.mode == MapMode.Return) {
      // Zoom to the selected pickup location
      setTimeout(() => {
        this.map.panTo({ lat: this.selectedPickupLocation.Latitude, lng: this.selectedPickupLocation.Longitude });
        this.map.setZoom(6);
      }, 50);
    } else if (this.locations && !this.goneToPlace && coords && coords.Latitude && coords.Longitude) {
      this.goneToPlace = true;

      // Update distances
      this.locations.map(location => location.Distance = this.appService.getDistance(coords, { Latitude: location.Latitude, Longitude: location.Longitude }));

      // Zoom into the closest location
      var closest;
      if (this.bookingState.objectCategory)
        closest = this.locations.filter(x => (this.mode == MapMode.Local || x.OpenForPickup) && x.HasFamilies?.includes(this.bookingState.objectCategory.Id))
          .sort((a: LocationInfo, b: LocationInfo) => a.Distance <= b.Distance ? -1 : 1)[0];
      else {
        closest = this.locations.filter(x => this.mode == MapMode.Local || x.OpenForPickup)
          .sort((a: LocationInfo, b: LocationInfo) => a.Distance <= b.Distance ? -1 : 1)[0];
      }

      // #846: If we have a selected Category, we only check the station that have the type.
      // Only if we dont already have the country.
      var anticipatedCountry = this.appSettings.getAnticipatedUserCountry();
      if (!anticipatedCountry && closest || (anticipatedCountry && anticipatedCountry != closest.Country)) {
          //Saves the closet location, if there is none, we have Sweden as fallback.
        await this.appSettings.setAnticipatedUserCountry(closest.Country ?? "Sweden");
      }
      // Already a selected location? Use it!
      if (this.selectedLocation)
        closest = this.selectedLocation;

      var list = [];
      list.push(new google.maps.LatLng(closest.Latitude, closest.Longitude));
      if (this.searchPin) list.push(this.searchPin.getPosition());
      if (this.selectedPickupLocation) list.push(new google.maps.LatLng(this.selectedPickupLocation.Latitude, this.selectedPickupLocation.Longitude));

      setTimeout(() => {
      this.map.fitBounds(this.CalculateBounds(list), 15);

      
        //this.map.panTo({ lat: coords.Latitude, lng: coords.Longitude });
        //this.map.setZoom(zoom.zoom);


        let marker = this.markers.find(x => x.locationId == closest.Id);
        if (marker) {
           //Temporarly solution to display that the station have moved to Use4Free from BauhausDK.
          if ((this.appSettings.contextId == "BauhausUnmanned_API" || this.appSettings.contextId == '1DD8D261-275E-4C9D-8CFC-71A971BA3E38') && this.bauhausStations[closest.Id] && this.checkIfDateIsPassed(closest.Id)) {
            this.stationMovedToU4F(marker, closest);
          } else
          if (this.appSettings.contextId == "KanLeiesUnmanned_API" && !closest.OpenForPickup) {
            this.openFutureLocatioInfo(marker.marker, closest);
          } else {
            this.openPopup(marker.marker, closest);
          }
        }
      }, 50);
    } else if (coords && coords.Latitude && coords.Longitude) {
      setTimeout(() => {
        this.map.panTo({ lat: coords.Latitude, lng: coords.Longitude });
        this.map.setZoom(8);
      }, 50);
    }

  }

  // Remove and re-add all markers on the map
  public async updateMarkers() {
    if (this.locations) {
      for (var item of this.locations) {
        this.addLocation(item, 50);
      }
    }
  }

  // Create a pin and add it to the map
  private addLocation(item: LocationInfo, delay: number) {
    // For Use4Free dont show closed locations in app
    if (this.appSettings.contextId == "KanLeiesUnmanned_API" && !item.OpenForPickup && this.appSettings.appType == 'app') {
      return;
    }

    var marker = this.markers.find(x => x.locationId == item.Id)?.marker;
    var firstRun = false;
    if (!marker) {
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(item.Latitude, item.Longitude),
        icon: "",
        title: item.Name + ", " + item.Address,
        zIndex: 2000,
      });
    

      var circlee = new google.maps.Circle({
        strokeColor: "#ADD8E6",
        strokeOpacity: 0.8,
        fillColor: "#32CD32",
        strokeWeight: 1,
        map: this.map,
        fillOpacity: 0.35,
        radius: item.TrailerPointRadius
      })


      circlee.bindTo('center', marker, 'position');

      this.markers.push({ locationId: item.Id, location: item, marker: marker, circle: circlee });
      firstRun = true;
    }

    // Return && Selected pickup?
    if (this.mode == MapMode.Return && item.Id == this.selectedPickupLocation.Id) {
      this.setMarkerImage(marker, "Pin_Pickup");
      marker.setZIndex(200);
    }
    // Return && Not open for return?
    else if (this.mode == MapMode.Return && !item.OpenForReturn) {
      this.setMarkerImage(marker, "Pin_Disabled");
      marker.setZIndex(100);

      if (firstRun) {
        marker.addListener('click', () => {
          this.openDisabledReturnInfo(marker, item);
        });
      }
    }
      // Pickup && Not open for pickup?
    else if (this.mode == MapMode.Pickup && !item.OpenForPickup) {
      this.setMarkerImage(marker, "Pin_Disabled");
      marker.setZIndex(100);

      if (firstRun) {
        marker.addListener('click', () => {
          this.openDisabledPickupInfo(marker, item);
        });
      }
    }
      // Return && Selected?
    else if (this.mode == MapMode.Return && item == this.selectedLocation) {
      this.setMarkerImage(marker, "Pin_Return");
      marker.setZIndex(300);
    // Standard marker
    } else {
    
      if (this.bookingState.objectCategory != null && !item.HasFamilies?.includes(this.bookingState.objectCategory.Id)) {
        //Adds a opacity to the marker, and in turn adds a grayscale through css.
        this.setMarkerImage(marker, "Pin", true);
        marker.setZIndex(100);
      }
      else {
        this.setMarkerImage(marker, "Pin");
        marker.setZIndex(150);
      }
      
      if (firstRun) {
        marker.addListener('click', () => {
          var list = [];
          list.push(new google.maps.LatLng(item.Latitude, item.Longitude));
          if (this.searchPin) list.push(this.searchPin.getPosition());
          if (this.selectedPickupLocation) list.push(new google.maps.LatLng(this.selectedPickupLocation.Latitude, this.selectedPickupLocation.Longitude));

          this.map.panToBounds(this.CalculateBounds(list), 25);

          setTimeout(async () => {
            if (this.appSettings.contextId == "KanLeiesUnmanned_API" && !item.OpenForPickup) {
              this.openFutureLocatioInfo(marker, item);
            }
            else if (this.bookingState.objectCategory != null && !item.HasFamilies?.includes(this.bookingState.objectCategory.Id)) {
              this.openNoObjectWithFilter(marker, item);
            }
            //Temporarly solution to display that the station have moved to Use4Free from BauhausDK.
            else if ((this.appSettings.contextId == "BauhausUnmanned_API" || this.appSettings.contextId == '1DD8D261-275E-4C9D-8CFC-71A971BA3E38') && this.bauhausStations[item.LocationId] && this.checkIfDateIsPassed(item.LocationId)) {
              this.stationMovedToU4F(marker, item);
            }
            else {
              await this.openPopup(marker, item);
            }
            this.updateMarkers();
          }, 50);
        });
      }
    }

    marker.setMap(this.map);
    return marker;
  }

  private bauhausStations: { [key: number]: string } = {
  10017: '2024-05-01', //BAUHAUS Ishøj
  10022: '2024-05-01', //BAUHAUS Viby J
  10019: '2024-05-01', //BAUHAUS Hillerød
  10024: '2024-05-01', //BAUHAUS Holbæk
  10027: '2024-05-01', //BAUHAUS Glostrup
  10031: '2024-05-01', //BAUHAUS Tilst
  11625: '2024-05-01', //BAUHAUS Gladsaxe
  12527: '2024-05-01', //BAUHAUS Sønderborg
  10016: '2024-05-14', //BAUHAUS Roskilde 
  10018: '2024-06-12', //BAUHAUS Hjørring
  10020: '2024-06-13', //BAUHAUS Randers Sø 
  10021: '2024-05-21', //BAUHAUS Herning
  10023: '2024-05-30', //BAUHAUS Vejle 
  10025: '2024-05-08', //BAUHAUS Næstved 
  10026: '2024-06-11', //BAUHAUS Esbjerg N
  10028: '2024-06-04', //BAUHAUS Aalborg Sv
  10029: '2024-05-28', //BAUHAUS Odense C
  10030: '2024-05-23', //BAUHAUS Kolding 
  10588: '2024-05-16', //BAUHAUS Valby 
};

  private checkIfDateIsPassed(stationId: number): boolean {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
    const movedDate = this.bauhausStations[stationId];
    return currentDate > movedDate;
  }


  private CalculateBounds(list: google.maps.LatLng[]): google.maps.LatLngBounds {
    var x0, x1, y0, y1;
    for (var latLng of list) {
      if (x0 == null) {
        x0 = x1 = latLng.lat();
        y0 = y1 = latLng.lng();
      } else {
        if (latLng.lat() > x1) x1 = latLng.lat();
        if (latLng.lat() < x0) x0 = latLng.lat();
        if (latLng.lng() > y1) y1 = latLng.lng();
        if (latLng.lng() < y0) y0 = latLng.lng();
      }
    }

    if (list.length > 1) {
      y0 -= Math.max((y1 - y0) * 0.5, 0.01);
      y1 += Math.max((y1 - y0) * 0.2, 0.01);
    } else {
      x0 -= 0.1;
      x1 += 0.1;
      y0 -= 0.1;
      y1 += 0.1;
    }


    return new google.maps.LatLngBounds(new google.maps.LatLng(x0, y0), new google.maps.LatLng(x1, y1));
  }

  private valBook = "";
  private async openPopup(marker: google.maps.Marker, location: LocationInfo) {
    this.goneToPlace = true;

    if (this.valBook == "") {
      if (this.mode == MapMode.Local)
          this.valBook = await this.translationsService.translate("Intellitrailer.Home.cmdBook2", "Book a ticket");
        else
          this.valBook = await this.translationsService.translate("Intellitrailer.ReturnLocation.cmdSelect", "Select");
    }

    if (!this.infoWindow) this.infoWindow = new google.maps.InfoWindow();
    this.infoWindow.setContent(`<div class="map-box-title">${location.Name}</div><div class="map-box-text">${location.Address}<br/>${location.City}<br/><button type="button" class="map-button" onClick="bookFromMap()">${this.valBook}</button></div>`);
    this.infoWindow.open(this.map, marker);
    this.selectedLocation = location;
    this.selectedLocationChange.emit(this.selectedLocation);

    // Find routes and calculate distances if in return mode
    if (this.mode == MapMode.Return) {
      if(!this.directions) this.directions = new google.maps.DirectionsService();
      this.directions.route({
        origin: new google.maps.LatLng(this.selectedPickupLocation.Latitude, this.selectedPickupLocation.Longitude),
        destination: new google.maps.LatLng(this.selectedLocation.Latitude, this.selectedLocation.Longitude),
        travelMode: google.maps.TravelMode.DRIVING,
        optimizeWaypoints: false
      }, (routes, status) => {
        if (status == google.maps.DirectionsStatus.OK) {
          this.selectedDistance = 0;
          this.selectedDuration = 0;
          routes.routes[0].legs.map(x => this.selectedDistance += (x.distance.value / 1000.0));
          routes.routes[0].legs.map(x => this.selectedDuration += (x.duration.value / 60.0 / 60.0));
          this.selectedDurationHours = Math.floor(this.selectedDuration);
          this.selectedDurationMinutes = (this.selectedDuration - this.selectedDurationHours) * 60.0;

          if (!this.directionsRenderer) this.directionsRenderer = new google.maps.DirectionsRenderer({ suppressMarkers: true, preserveViewport: true });
          this.directionsRenderer.setDirections(routes);
          this.directionsRenderer.setMap(this.map);
        }
      });
    }
  }

  private async openFutureLocatioInfo(marker: google.maps.Marker, location: LocationInfo) {
    var message = await this.Translate.translate('Intellitrailer.MapComponent.msgLocationIsNotActive', 'This locations isn\'t yet active in Use4Free.');

    var cnt = `<div class="map-box-title disabled">${location.Name}</div><div class="map-box-text">${message}</div>`;
    if (this.appSettings.appType != 'app' && this.selectedLocation.RentalLocationGroupId != null && this.selectedLocation.RentalLocationGroupId == 4943) // Hornbach GroupId
      cnt += `<br/><a href="https://www.hornbach.se/service/hyr-lastbil-och-lana-slap/ " target="_blank">Klicka här för information och bokning</button>`

    if (!this.infoWindow) this.infoWindow = new google.maps.InfoWindow();
    this.infoWindow.setContent(cnt);
    this.infoWindow.open(this.map, marker);
  }

  private async openDisabledPickupInfo(marker: google.maps.Marker, location: LocationInfo) {
    var message = await this.Translate.translate('Intellitrailer.MapComponent.msgLocationTemporarlyClosedReturn' , 'Station is currently closed for returns.')

    if (!this.infoWindow) this.infoWindow = new google.maps.InfoWindow();
    this.infoWindow.setContent(`<div class="map-box-title disabled">${location.Name}</div><div class="map-box-text">${message}</div></div>`);
    this.infoWindow.open(this.map, marker);
  }

  private async openDisabledReturnInfo(marker: google.maps.Marker, location: LocationInfo) {
    var message = await this.Translate.translate('Intellitrailer.MapComponent.msgLocationTemporarlyClosedPickup', 'Station is currently closed for pickup.')

    if (!this.infoWindow) this.infoWindow = new google.maps.InfoWindow();
    this.infoWindow.setContent(`<div class="map-box-title disabled" >${location.Name}</div><div class="map-box-text">${message}.</div></div>`);
    this.infoWindow.open(this.map, marker);
  }

  private async openNoObjectWithFilter(marker: google.maps.Marker, location: LocationInfo) {
    var message = await this.Translate.translate('Intellitrailer.MapComponent.msgNoObjectOfSelectedKind', 'This location has no rental object of the selected type.');

    if (!this.infoWindow) this.infoWindow = new google.maps.InfoWindow();
    this.infoWindow.setContent(`<div class="map-box-title disabled">${location.Name}</div><div class="map-box-text">${message}</div></div>`);
    this.infoWindow.open(this.map, marker);
  }


  private async stationMovedToU4F(marker: google.maps.Marker, location: LocationInfo) {
   /* var message = await this.Translate.translate('Intellitrailer.MapCompoent.msgStationMovedToU4F', 'This station have moved to the Use4Free app.');*/
    var message = "Trailerudlejningen i dette varehus er flyttet til tjenesten Use4Free."
    /*var additionalMessage = await this.Translate.translate('Intellirailer.MapCompoent.msgStationMovedAppInfo', 'Get the app here.')*/
    var additionalMessage = "Klik her for at downloade appen."


    if (!this.infoWindow) this.infoWindow = new google.maps.InfoWindow();
    this.infoWindow.setContent(`<div class="map-box-title use4free">${location.Name}</div><div class="map-box-text">${message}</div> <br/><a href="https://r.turbo4.net/U4F" target="_blank">${additionalMessage}</button>`);
    this.infoWindow.open(this.map, marker);
  }

  private setMarkerImage(marker: any, type: string , disabled: boolean = false) {
    const theme = this.appSettings.settings.theme || 'Default';
    marker.setIcon("/Styles/" + theme + "/" + type + ".png");
    if (disabled) marker.setOpacity(0.51);
  }
}
