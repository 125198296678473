import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { PageBase, RentalInfo, RentalStatus } from 't4core';
import { AppIntegrationService, AppSettingsService, FindLockResult } from 't4-app-integration';
import { NavigationResolver, NavigationService, ActiveRentalService, LayoutService } from 'app-components';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nfc-nolock',
  templateUrl: './nfc-nolock.component.html',
  styleUrls: ['./nfc-nolock.component.css']
})
export class NFCNoLockComponent extends PageBase implements OnInit {

  public count = 0;
  public unlocked = false;

  public booking: RentalInfo;
  public status = RentalStatus;
  public firstLoad: boolean = true;
  public objectId: number;
  public unlockingInfo: string;
  public alternativesLoaded: boolean = false;
  public isAndroid: boolean = false;
  public isBauhaus: boolean = false;

  public event: string = ""; // Pickup/Return/Active etc.

  public ignoreBTCheck: boolean = false;
  public ignoreLocationCheck: boolean = false;

  // States
  public isWaiting: boolean = true;
  public isSearching: boolean = false;
  public isUnlocked: boolean = false;
  public lockNotFound: boolean = false;
  public hasFailed: boolean = false;
  public hasCompleted: boolean = false;

  public failReason: number;

  private timerSubscription: Subscription;

  constructor(el: ElementRef, public appSvc: AppIntegrationService, private aviationService: NavigationService, private rentalService: ActiveRentalService, public layoutService: LayoutService, public resolver: NavigationResolver, public appSettings: AppSettingsService) {
    super(el);
    this.appSvc.requestDeviceState();

    this.event = this.aviationService.getParam("event");
    this.timerSubscription = this.appSvc.getSecondTimer().subscribe(x => {
      if (this.isWaiting) this.appSvc.requestDeviceState();
      if (this.isWaiting && (this.ignoreBTCheck || this.appSvc.Device.Bluetooth >= 2) && (this.ignoreLocationCheck || this.appSvc.Device.Location >= 2)) this.beginUnlock();

      if (!this.isWaiting && !this.hasFailed) {

        if (this.count < 30) {
          this.count++;
        }
      }
    });
  }

  async ngOnInit() {
    this.booking = await this.rentalService.getBooking();
    this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: "Begin Nfc unlock!" });

    this.objectId = this.booking.ObjectId;
    if (this.isWaiting && (this.ignoreBTCheck || this.appSvc.Device.Bluetooth >= 2) && (this.ignoreLocationCheck || this.appSvc.Device.Location >= 2)) this.beginUnlock();
  }

  public next() {
    this.count = 0;
    if (this.isWaiting) {
      this.isWaiting = false;
      this.isSearching = true;
    } else if (this.isSearching) {
      this.isSearching = false;
      this.isUnlocked = true;
    } else if (this.isUnlocked) {
      this.timerSubscription.unsubscribe();
      this.hasCompleted = true;
    }
  }

  private fail(reason?: number) {
    if (this.hasFailed) return;
    this.hasFailed = true;

    if (this.isSearching) {
      this.isSearching = false;
      this.lockNotFound = (reason == null);
    } else if (this.isUnlocked) {
      this.isUnlocked = false;
    }

    this.hasFailed = true;

    if (reason)
      this.failReason = reason;
    else {
      if (this.appSvc.Device.Location < 2)
        this.failReason = 10;
      else if (this.appSvc.Device.Location < 3)
        this.failReason = 20
    }

    if (!reason && this.retryCount > 1) {
      this.failReason = -1;
    }
  }

  public skip() {
    this.aviationService.executeCommand("Skip");
  }

  private retryCount: number = 0;
  public retry() {
    this.retryCount++;

    this.count = 0;
    if (this.hasFailed) {
      this.lockNotFound = false;
      this.hasFailed = false;
      this.failReason = null;
      this.isWaiting = true;
      this.beginUnlock();
    }
  }

  public continueUnlock() {
    if (this.hasFailed) {
      this.lockNotFound = false;
      this.hasFailed = false;
      this.failReason = null;
      this.isWaiting = true;
      this.beginUnlock();
    }
  }

  public openSettings() {
    this.appSvc.openPermissions();
  }



  public async beginUnlock() {
    this.next();

    var lockId = this.booking.RentalObject.Locks.map(x => x.Id)[0];

    // Ask the device to start searching
    this.Insights.logEvent("Looking for Nfc tag: " + lockId);
    var ts = new Date();
    //expecting ret.Mac = "u4fAAAAAAAA";
    var ret = await this.test();
    
    var diff = new Date().getTime() - ts.getTime();
    this.Insights.logEvent("Timing: " + diff + "ms");

    // Handle result
    if (ret && ret.Mac == lockId) {
      // Log finding
      var info = "Found Nfc tag: " + ret;
      if (this.booking) this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: info });
      this.Insights.logEvent(info);

      // Continue process
      this.next();
    } else {
      this.fail(ret?.Reason);

      // Log
      var info = "Could not connect to the lock.";
      if (ret?.Reason == null) {


        if (this.appSvc.Device.Location < 2)
          info += ', Location not permitted';
        else if (this.appSvc.Device.Location < 3)
          info += ', Location not enabled';
        else
          info += ' Timed out';

        this.Insights.logEvent(info);
      }
      else if (ret?.Reason == 0) {
        this.Insights.logEvent("BT access denied");
        info += " Bluetooth permission is not granted"
      }
      else if (ret?.Reason == 1) {
        this.Insights.logEvent("BT not enabled");
        info += " Bluetooth is not enabled on device";
      }
      else {
        this.Insights.logEvent("Unknown lock error");
        info += " Unknown error";
      }

      if (this.booking) this.Api.get<boolean>("/Rental/SetRentalEvent", { rentalId: this.booking.Id, content: info });

      return;
    }
    this.next();
  }

  public skipBTCheck() {
    this.ignoreBTCheck = true;
  }

  public skipLocationCheck() {
    this.ignoreLocationCheck = true;
  }

  public nextStep() {
    this.rentalService.fetchSecurity(false);
    this.aviationService.executeCommand("Next");
  }

  public delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public async test(): Promise<FindLockResult> {
    await this.delay(10000);
    if (this.count >= 30) {
      return {
        Success: false,
        Mac: "",
        Session: ""
      };
    }
    var ret = new FindLockResult();
    ret.Mac = "u4fAAAAAAAA";
    ret.Success = true;
    return ret;
  }
}
