<app-view-title></app-view-title>
<div class="view-content centered-view" style="padding: 25px">
  <div class="securiy-check-title" *ngIf="event != 'FaultReport'">
    {{ 'Intellitrailer.Rental.SecurityCheck.Headline' | translate : 'Security check' }}
  </div>
  <div class="security-check-explanation" *ngIf="event != 'FaultReport'">
    {{ 'Intellitrailer.Rental.SecurityCheck.lblCheckCurrentCond' | translate : 'Check the current condition of the trailer below:' }}
  </div>
  <div class="propertyTable">
    <div class="row justify-content-left mb-3">
      <div class="col col-6">&nbsp;</div>
      <div class="col col-3">
        <div class="icon-circle green">
        </div>
        <span class="green-ok">OK</span>
      </div>
      <div class="col col-3">
        <div class="icon-circle red">
        </div>
        <span class="red-faulty">{{ 'Intellitrailer.Rental.SecurityCheck.Faulty' | translate : 'Faulty' }}</span>
      </div>
    </div>
    <div class="row justify-content-left mt-2" *ngFor="let p of securityCheck.CheckPoints">
      <div class="col col-6">{{ 'Intellitrailer.Rental.SecurityCheck.CheckPoint.' + p.Key | translate}}</div>
      <div class="col col-3">
        <button class="circle-button" mat-raised-button (click)="setCheck(p, true)" [ngClass]="p.Ok ? 'checkedg' : 'unchecked'"><mat-icon class="done" *ngIf="p.Ok">done</mat-icon></button>
        <mat-checkbox *ngIf="false" class="checkOk" [(ngModel)]="p.Ok"></mat-checkbox>
      </div>
      <div class="col col-3">
        <button class="circle-button" mat-raised-button (click)="setCheck(p, false)" [ngClass]="p.Failed ? 'checkedr' : 'unchecked'"><mat-icon class="clear" *ngIf="p.Failed">clear</mat-icon></button>
        <mat-checkbox *ngIf="false" class="checkFailed" [(ngModel)]="p.Failed">
        </mat-checkbox>
      </div>
    </div>
  </div>
  <mat-form-field appearance="outline">
    <textarea matInput placeholder="{{ 'Intellitrailer.Rental.SecurityCheck.txtRemarksInfo' | translate : 'Additional feedback about the trailer' }}" [(ngModel)]="securityCheck.Remarks" rows="2"></textarea>
  </mat-form-field>
  <p *ngIf="securityCheck.HasDeviation">{{ 'Intellitrailer.Rental.SecurityCheck.txtFaultsInfo' | translate : 'You have marked something as faulty. In the next step you will be asked to take a photo of that part to document it for us.' }}</p>
</div>
<div class="view-actions">
  <button mat-raised-button *ngIf="event != 'FaultReport'" [disabled]="securityCheck.IsValid" (click)="confirm()">{{ 'Intellitrailer.Rental.SecurityCheck.cmdConfirm' | translate : 'Confirm' }}</button>
  <button mat-raised-button *ngIf="event == 'FaultReport'" [disabled]="securityCheck.IsValid" (click)="confirm()">{{ 'Intellitrailer.Navigation.Next' | translate : 'Next' }}</button>

</div>

