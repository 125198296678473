import { Component, OnInit, ElementRef, EventEmitter, Output } from '@angular/core';
import { PageBase } from 't4core';
import { AppIntegrationService } from 't4-app-integration';

@Component({
  selector: 'app-cameraPermissionRequired',
  templateUrl: './cameraPermissionRequired.html'
})
export class CameraPermissionRequired extends PageBase implements OnInit {

  public permissionAsked: boolean = false;
  @Output()
  public next: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(el: ElementRef, private appService: AppIntegrationService) {
    super(el);
  }

  ngOnInit(): void { }

  public askForPermission() {
    this.next.emit(true);
    //this.appService.takePhoto('','');
    this.permissionAsked = true;
  }

  public openSettings() {
    this.appService.openPermissions();
  }
}
