import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RentalInfo, ObjectTypeInfo, ComponentBase, PriceModel, RentalStatus, UIService, DibsPaymentType, DiscountModel, IntervalPrice } from 't4core';
import * as moment from 'moment';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';
import { RentalAgreementComponent } from '../../dialogs/rental-agreement/rental-agreement.component';
import { BookingService } from 'app-components';
import { UntypedFormControl } from '@angular/forms';
import { AddonPopupComponent } from '../../dialogs/addon-popup/addon-popup.component';
import { LayoutService } from 'app-components';
import { ObjectTypeInfoWithIntervalPrice } from '../../../../lib/Models/ObjectTypeInfoWithIntervalPrice';


@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.css']
})
export class BookingSummaryComponent extends ComponentBase implements OnInit {
  @Input() public booking: RentalInfo;
  @Output() public bookingChange = new EventEmitter<RentalInfo>();
  @Input() public confirmed = false;
  @Input() public atLocation = false;
  @Input() public editingExistingBooking: boolean = false;
  showDiscount: boolean = false;
  @Input()
  set addonsFromPopup(value: ObjectTypeInfo[]) {
    this.addedProductChangedFromPopup(value);
  }
  @Output() sendNext = new EventEmitter<boolean>();
  @Output() changeSavedCard = new EventEmitter<boolean>();
  @Output() changeTerms = new EventEmitter<boolean>();
  @Output() changePrice = new EventEmitter<PriceModel>();
  @Output() specialConditionChanged = new EventEmitter<boolean>();

  public addons: ObjectTypeInfoWithIntervalPrice[];
  public addedPriceProds: ObjectTypeInfo[] = [];
  public showAddonInfo: boolean[] = [];
  @Output() addableTypesChanged = new EventEmitter<ObjectTypeInfo[]>();
  public isAddedProductsChanged: Boolean = false;
  public currentPrice: PriceModel = new PriceModel();
  public savedCardAvailable = false;
  public savedCard = false;
  public terms = false; 
  public rentalStatus = RentalStatus;
  //public paymentType: number;
  public dibsPayment = DibsPaymentType;
  public rentalAgreement: string;
  public mapLink: string;
  public typeImage: string;
  public discountCode: string = "";
  public incorrectDiscount: boolean = false;
  public discount: DiscountModel = null;

  public rentalPeriodText: string;
  public addedPriceProdsTranslations: string[] = [];
  public discountControl = new UntypedFormControl('');
  public shownAddons: ObjectTypeInfo[];

  public extraConditionExist: boolean = false;
  public specialConditionChecked: boolean = false;



  constructor(private TheUI: UIService, private appService: AppIntegrationService, public dialog: MatDialog, public bookingService: BookingService, private layoutService: LayoutService, private appSettings: AppSettingsService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    if (this.booking && this.booking.PickupLocation) {
      //this.typeImage = "https://turbo3.brenderuprental.com/Handlers/RentalObjectTypePictureHandler.ashx?k=" + this.booking.PickupLocation.RentalLocationGroupId + "&n=" + this.booking.PrimaryType.Id + "&t="
      this.typeImage = this.booking.PrimaryType.PictureUri;
    }
    else {
      this.typeImage = this.booking.PrimaryType.PictureUri;
    }

    const varebilPrimaryObjectTypes = [657, 873, 874, 876, 933, 950, 415, 1088];

    if (varebilPrimaryObjectTypes.includes(this.booking.PrimaryObjectTypeId))
      this.rentalPeriodText = await this.Translate.translate('Intellitrailer.Booking.UnConfirmedBooking.RentVarebil', 'Rent');
    else
     this.rentalPeriodText = await this.Translate.translate('Intellitrailer.Booking.UnConfirmedBooking.Rent', 'Rent');

    var token = this.TheUI.beginLoading("Intellitrailer.Booking.BookingSummary.msgLoading", "Checking availability", null);
    this.rentalAgreement = await this.Api.get<string>("Settings/GetAgreementText");
    await this.updateAddableProducts();
    await this.addDiscount();
    await this.checkForSavedCard();
    //this.paymentType = await this.getDibsPaymentType();
    
    if (this.booking.PrimaryType.SpecialCondition) {
      this.extraConditionExist = true;
    }

    var iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

    if (iOS) {
      this.mapLink = 'http://maps.apple.com/?q=' + this.booking.PickupLocation.Latitude + "," + this.booking.PickupLocation.Longitude;
    }
    else {
      this.mapLink = "https://maps.google.com/?q=" + this.booking.PickupLocation.Latitude + "," + this.booking.PickupLocation.Longitude;
    }

    if (this.booking.DiscountId) this.showDiscount = true;

    this.UI.loaderCompleted(token);
  }

  //public async getDibsPaymentType(): Promise<number> {
  //    return await this.Api.get<any>("/Settings/getDibsPaymentType");
  //}

  public async updateAddableProducts() {
    this.addons = await this.Api.get<ObjectTypeInfo[]>("/RentalObject/GetAddons", { typeId: this.booking.PrimaryType.Id, locationId: this.booking.PickupLocationId });
    for (let type of this.addons) {
      this.showAddonInfo.push(false);
    }
    this.addableTypesChanged.emit(this.addons);
    this.shownAddons = this.addons.filter(x => x.IsHidden === false);
    this.updateAddedProducts();

    await this.getAddonPrices();
  }

  public async getAddonPrices() {
    for (let addon of this.addons) {
      var values = await this.Api.get<IntervalPrice>("/Price/GetAddonPriceInfo", { addonId: addon.Id });
      if (values) {
        addon.Price = values.Price;
        addon.Period = values.Period;
      }
    }
  }

    public async addedProductChangedFromPopup(products: ObjectTypeInfo[], goNext = true) {
        if (!products) return;

        for (let product of products) {
            var index = this.addons.findIndex(x => x.Id == product.Id);
            if (index > -1) {
              var e = new MatSlideToggleChange(null, product.IsSelected);
              await this.addedProductChanged(this.addons[index], e.checked);
            }
        }

        if (goNext) this.sendNextToParent();
    }

    public sendNextToParent() {
        this.sendNext.emit(true);
    }

    public async openMaps(url: string) {
        this.appService.openExternal(url);
  }

  public async addedProductChanged(product: ObjectTypeInfo, add: boolean) {
      
        if (add) {
            this.addAddedProduct(product);
        } else {

          if (product.AskIfNotSelected) {
            const dialogRef = this.dialog.open(AddonPopupComponent, {
              width: '350px',
              position: { top: '50px' },
              data: { product: product, booking: this.booking, confirm: false }
            });

            var result = await dialogRef.afterClosed().toPromise();
            if (result == true) {
              product.IsSelected = true;
              return;
            }
          }

            this.removeAddedProduct(product);
        }
        await this.updatePrice();
        this.isAddedProductsChanged = true;
        this.bookingChange.emit(this.booking);
        this.addableTypesChanged.emit(this.addons);
  }


    private async updateAddedProducts() {
        var firstLoad: boolean = this.booking.AddedProducts == null ? true : false;
        if (!this.booking.AddedProducts) this.booking.AddedProducts = "";
        if (!this.booking.AddedProductIds) this.booking.AddedProductIds = [];

        if (firstLoad) {
            for (var i of this.addons) {
                if (i.IsDefault) {
                    this.addAddedProduct(i);
                    this.isAddedProductsChanged = true;
                }
            }
        } else {
            for (var t of this.booking.AddedProductIds) {
                var p = this.addons.find(x => x.Id == t);
                if (p) this.addAddedProduct(p);
                else {
                    if (this.booking.AddedProductIds.indexOf(t) >= 0) this.booking.AddedProductIds.splice(this.booking.AddedProductIds.indexOf(t));
                    this.booking.AddedProducts = this.booking.AddedProducts.replace(t + ";", "");

                    // Replacement?
                    var replacement = this.addons.find(x => x.ReplacementFor && x.ReplacementFor.indexOf(t + ";") >= 0);
                    if (replacement) this.addAddedProduct(replacement);
                }
            }
        }

        await this.updatePrice();
    }

    private addAddedProduct(product: ObjectTypeInfo) {
        if (this.booking.AddedProducts.indexOf(product.Id + ";") < 0) {
            this.booking.AddedProducts += product.Id + ";";
            this.booking.AddedProductIds.push(product.Id);
        }
        product.IsSelected = true;

        for (var i of this.addons) {
            if (i.Id == product.Id || !i.IsSelected) continue;
            if (i.CheckCondition && eval(i.CheckCondition) !== true) {
                this.removeAddedProduct(i);
            }
        }
    }

    private removeAddedProduct(product: ObjectTypeInfo) {
        product.IsSelected = false;
        this.booking.AddedProducts = this.booking.AddedProducts.replace(product.Id + ";", "");
        if (this.booking.AddedProductIds.indexOf(product.Id) >= 0) this.booking.AddedProductIds.splice(this.booking.AddedProductIds.indexOf(product.Id), 1);
    }

    public async updatePrice() {

      var thirtyMinsFromNow = moment().add(30, 'minutes');
      var webBooking = true;//!this.atLocation;

      if (!webBooking && this.booking.PickupTime >= thirtyMinsFromNow) {
        webBooking = true;
      }

      var currentCoordinates = await this.appService.getCoordinates();
      if (this._isDestroyed) return;

        this.currentPrice = await this.Api.get<PriceModel>("/Price/GetRentalPrice",
            {
                pickupTime: this.booking.PickupTime ? this.booking.PickupTime.toISOString() : moment().toISOString(),
                returnTime: this.booking.ReturnTime.toISOString(),
                objectTypeId: this.booking.PrimaryObjectTypeId,
                pickupLocationId: this.booking.PickupLocation.Id,
                returnLocationId: this.booking.ReturnLocation ? this.booking.ReturnLocation.Id : this.booking.PickupLocation.Id,
                useInsurance: false,
                webBooking: await this.bookingService.isWebBooking(),
                actualReturnTime: this.booking.ReturnedAt ? this.booking.ReturnedAt.toISOString() : (this.booking.Status == RentalStatus.Late ? moment().toDate().toISOString() : this.booking.ReturnTime.toISOString()),
                createdAt: this.booking.CreatedAt ? this.booking.CreatedAt.toISOString() : moment().toDate().toISOString(),
                discountId: this.booking.DiscountId,
                addedProducts: this.booking.AddedProducts,

                bookingLat: currentCoordinates ? currentCoordinates.Latitude : null,
                bookingLng: currentCoordinates ? currentCoordinates.Longitude : null
            });
        this.changePrice.emit(this.currentPrice);


        for (let prod of this.addons) {
            if (this.currentPrice.AddedProducts.find(x => x.ArticleNo == prod.Id)) {
                this.addedPriceProds.push(prod);
            }
        }

        for (let prod of this.currentPrice.AddedProducts) {
            if (prod.Name.indexOf('Turbo.') == 0) {
                var translation = await this.Translate.translate(prod.Name);
                translation = translation.replace(" (", "<br> (")
                this.addedPriceProdsTranslations.push(translation);
            }
            else this.addedPriceProdsTranslations.push("");
        }

    }

    public async checkForSavedCard() {
      //var user = this.appSettings.getUserId();
      //  if (user) {
      //    var customer = await this.Api.get<any>("/Rental/FindCustomerByUser");
      //    if (customer) {
      //      this.savedCardAvailable = customer.HasDibsTicket;
      //      this.savedCard = customer.HasDibsTicket;
      //    }
      //  }
    }

    public savedCardToggle(event: MatSlideToggleChange) {
        this.savedCard = event.checked;
        this.changeSavedCard.emit(this.savedCard);
    }

    public termsToggle(event: MatSlideToggleChange) {
        this.terms = event.checked;
        this.changeTerms.emit(this.terms);
    }


  public sendSpecialConditionToParent(event) {
    this.specialConditionChecked = event.checked;
    this.specialConditionChanged.emit(this.specialConditionChecked)
  }


    public async showTerms() {
      // SPECIAL CASE //
      const varebilPrimaryObjectTypes = [657, 873, 874, 876, 933, 950, 415, 1088];

      if (varebilPrimaryObjectTypes.includes(this.booking.PrimaryObjectTypeId)){
        const dialogRef = this.dialog.open(RentalAgreementComponent, { data: { agreementType: "bauhausVarebil" } });
      }
      else {
        const dialogRef = this.dialog.open(RentalAgreementComponent, { data: { agreementType: "booking" } });
      }

    }

    public togglAddonInfo(k: number) {
        this.showAddonInfo[k] = !this.showAddonInfo[k];
    }

    public async showInfo(type, title = null, key: string = null, cost: any = null) {
        //const dialogRef = this.dialog.open(RentalAgreementComponent, { data: { agreementType: "info", title: "Intellitrailer.Booking.BookingSummary.BookingFeeInfoTtl", key: "Intellitrailer.Booking.BookingSummary.BookingFeeInfoMsg"  } });
        if (type == 'bookingFee') {
            const dialogRef = this.dialog.open(RentalAgreementComponent, { data: { agreementType: "info", title: "Intellitrailer.Booking.BookingSummary.BookingFeeInfoTtl", key: "Intellitrailer.Booking.BookingSummary.BookingFeeInfoMsg" } });
        }
        else {
            const dialogRef = this.dialog.open(RentalAgreementComponent, { data: { agreementType: "info", title: title, key: key} });

        }
    }

  public async showDiscountCard() {
    this.showDiscount = true;
  }

  public async addDiscount() {
    var token = this.TheUI.beginLoading("Intellitrailer.Booking.BookingSummary.msgLoadingOptions", "Loading booking data", null);

    var discountLoadedFromBooking = this.booking.DiscountId && this.booking.DiscountId.length > 0;
    this.discountCode = discountLoadedFromBooking ? this.booking.DiscountId : this.discountCode;

    if (this.discountCode && this.discountCode.length > 0) {
      this.discount = await this.Api.get<DiscountModel>("Price/ValidateDiscount", {
        code: this.discountCode,
        pickupTime: this.booking.PickupTime,
        returnTime: this.booking.ReturnTime,
        pickupLocationId: this.booking.PickupLocation.Id,
        returnLocationId: this.booking.ReturnLocation ? this.booking.ReturnLocation.Id : this.booking.PickupLocation.Id,
        objectTypeId: this.booking.PrimaryType.Id,
        createdAt: this.booking.CreatedAt ? this.booking.CreatedAt : moment().format(),
        isWebBooking: await this.bookingService.isWebBooking(),
        isOneWay: false,
        newRequest: true,
        addedProducts: this.booking.AddedProducts
      });
      if (this.discount && !this.discount.IsEmpty) {
        this.booking.DiscountId = this.discountCode;
        this.updatePrice();
        this.incorrectDiscount = false;
        this.discountControl.setErrors({ 'incorrect': false });
        this.UI.loaderCompleted(token);
        if (!discountLoadedFromBooking) this.UI.alert("Intellitrailer.Booking.BookingSummary.DiscountAddedTtl", "Discount added!", "Intellitrailer.Booking.BookingSummary.DiscountAddedMsg", "The discount has been added to your booking.");
        return;
      }
    }

    this.incorrectDiscount = true;
    this.discountControl.setErrors({ 'incorrect': true });
    this.booking.DiscountId = "";
    this.UI.loaderCompleted(token);
  }

  public async removeDiscount() {
    this.booking.DiscountId = "";
    this.discountCode = "";
    this.updatePrice();
  }

}
