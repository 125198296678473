import { Component, OnInit, ElementRef } from '@angular/core';
import { PageBase, T4PriceService, PriceModel, CurrencyPipe } from 't4core';
import { NavigationService } from 'app-components';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { BookingService } from 'app-components';
import { ActiveRentalService } from 'app-components';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
})
export class PaymentComponent extends PageBase implements OnInit {
  private rentalId: number;
  private autoRedirect: boolean = true;
  private returnPath: string = "";

  private flow: string = "";

  public cost: PriceModel = null;
  public amount: string = "";

  public returnAction: string = "";
  public paymentFailed: boolean = false;
  public redirecting: boolean = false;

  constructor(private navigator: NavigationService, el: ElementRef, public appSettings: AppSettingsService, private bookingService: BookingService, private appService: AppIntegrationService, private BookingDataService: BookingService, private rentalService: ActiveRentalService, private priceSvc: T4PriceService) {
    super(el);

    // Get parameters
    this.rentalId = parseInt(this.navigator.getParam("rentalId"));
    this.autoRedirect = this.navigator.getParam("autoRedirect") == "true";
    this.returnPath = this.navigator.getParam("returnPath");
    this.flow = this.navigator.getParam("flow");

    // Has the user returned from the payment flow?
    this.returnAction = this.navigator.getParam("action"); // So where is this param (supposed to be) set?
    if (this.returnAction) {

      if (this.flow == "Booking") {
        // Initiate booking service and continue
        this.bookingService.restartSession(this.rentalId).then(() => {
          if (this.returnAction === "accept") {
            this.navigator.executeCommand("Success", { rentalId: this.rentalId });
          }
          else {
            this.paymentFailed = true;
          }
        });
      } else {
        // Initiate Rental Service and continue
        this.rentalService.setActiveRental(this.rentalId)
          .then(() => {
            if (this.returnAction === "accept") {
              this.navigator.executeCommand("Success", { rentalId: this.rentalId });
            }
            else {
              this.paymentFailed = true;
            }
          });
      }
    }
    // Prepare the payment UI
    else if (this.autoRedirect) {
      this.redirect();
    }
  }

  async ngOnInit() {
    this.cost = await this.Api.get<PriceModel>("/Price/GetRentalCost", { id: this.rentalId });
    this.amount = new CurrencyPipe(this.Settings).transform((this.cost as any).ToPay)
  }

  public retry() {
    this.redirect();
  }

  private redirect() {
    this.redirecting = true;

    // Extract variables
    var theme = this.appSettings.settings.theme;
    var appType = this.appSettings.settings.typeSettings;
    var subcontext = this.appSettings.getParam("subcontext");
    var inStore = this.bookingService.inStore;
    var language = this.appSettings.getParam("language"); 

    // Build returnPath with existing parameters
    let returnPath = this.returnPath;

    // Append variables if defined
    if (theme !== undefined) {
      returnPath += (returnPath.includes('?') ? '&' : '?') + 'theme=' + encodeURIComponent(theme);
    }
    if (appType !== undefined) {
      returnPath += (returnPath.includes('?') ? '&' : '?') + 'appType=' + encodeURIComponent(appType.appType);
    }
    if (subcontext !== undefined) {
      returnPath += (returnPath.includes('?') ? '&' : '?') + 'subcontext=' + encodeURIComponent(subcontext);
    }
    if (inStore !== undefined) {
      returnPath += (returnPath.includes('?') ? '&' : '?') + 'inStore=' + encodeURIComponent(inStore);
    }
    if (language !== undefined) {
      returnPath += (returnPath.includes('?') ? '&' : '?') + 'language=' + encodeURIComponent(language);
    }

    // Redirect to the updated returnPath
    this.BookingDataService.redirectToPayment(this.rentalId, returnPath);
  }
}
