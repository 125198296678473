<div class="container" *ngIf="booking && booking.Id && confirmed">
  <div class="row">
    <div class="col-12 booking-confirmation" *ngIf="booking.Status == rentalStatus.Canceled">
      <div class="container">
        <div class="row">
          <div class="col-4">
            <div class="booking-confirmation-icon">
              <span class="material-icons failed">
                close
              </span>
            </div>
          </div>
          <div class="col-8 booking-confirmation-info">
              <div class="booking-confirmation-title">
                {{ 'Turbo_Core_bookingTooltip_Status_' + booking.Status | translate }}
              </div>
              <div class="booking-confirmation-text">
                Bokningen är avbokad
              </div>
            </div>
        </div>
      </div>
    </div>

    <div class="col-12 booking-confirmation" *ngIf="booking.Status == rentalStatus.Reserved">
      <div class="container">
        <div class="row">
          <div class="col-4">
            <div class="booking-confirmation-icon">
              <span class="material-icons success">
                done
              </span>
            </div>
          </div>
          <div class="col-8">
            <div class="booking-confirmation-content-wrapper">
              <div class="booking-confirmation-content">
                <div class="booking-confirmation-title">
                  {{ 'Turbo_Core_bookingTooltip_Status_' + 50 | translate }}
                </div>
                <div class="booking-confirmation-text">
                  {{ 'Intellitrailer.Booking.Components.BookingSummary.BookingCreated' | translate : 'Your booking has been created'}}
                </div>
              </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row cards-container" style="margin-top: 10px">
  <div class="col-md rounded-border-card-content">
    <div class="container booking-summary-card">
      <div class="title-with-divider">
        <div class="title">{{ booking.PrimaryType?.Alias }}</div>
      </div>
      <div class="summary-content" style="text-align: center" *ngIf="booking.PrimaryType">
        <div class="summary-content-row">
          <span class="object-image" style="background-image: url({{ typeImage }});"></span>
        </div>
        <div class="object-property-wrapper" [ngStyle]="{'width': booking.PrimaryType.Height && booking.PrimaryType.Height.length > 1 ? '25%' : '33%' }">
          <h6>{{ 'Intellitrailer.Booking.Components.BookingOption.Length' | translate : 'Length'}}</h6>
          <p>{{ booking.PrimaryType.Length }}cm</p>
        </div>
        <div class="object-property-wrapper" [ngStyle]="{'width': booking.PrimaryType.Height && booking.PrimaryType.Height.length > 1 ? '25%' : '33%' }">
          <h6>{{ 'Intellitrailer.Booking.Components.BookingOption.Width' | translate : 'Width'}}</h6>
          <p>{{ booking.PrimaryType.Width }}cm</p>
        </div>
        <div *ngIf="booking.PrimaryType.Height && booking.PrimaryType.Height.length > 1" class="object-property-wrapper" style="width: 25%">
          <h6>{{ 'Intellitrailer.Booking.Components.BookingOption.Height' | translate : 'Height'}}</h6>
          <p>{{ booking.PrimaryType.Height }}cm</p>
        </div>
        <div class="object-property-wrapper" [ngStyle]="{'width': booking.PrimaryType.Height && booking.PrimaryType.Height.length > 1 ? '25%' : '33%' }">
          <h6>{{ 'tIntellitrailer.Booking.Components.BookingOption.LoadWeight' | translate : 'LoadWeight'}}</h6>
          <p>{{ booking.PrimaryType.MaxLoad }}kg</p>
        </div>
      </div>
    </div>
    <div class="container booking-summary-card">
      <div class="title-with-divider">
        <div class="title">{{ 'Intellitrailer.Booking.Components.BookingSummary.RentalTimes' | translate : 'Rent times'}}</div>
      </div>
      <div class="summary-content">
        <span class="material-icons rent-times-divider" style="color: var(--action-secondary-background) ">
          arrow_right_alt
        </span>
        <div class="rent-time-wrapper">
          <h6>{{ 'turbo.core.RentalDialog.Pickup' | translate : 'Pickup'}}</h6>
          <p>{{ booking.PickupTime | date : 'd/M' }} - {{ booking.PickupTime | date : 'HH:mm' }}</p>
        </div>
        <div class="rent-time-wrapper rent-time-return-wrapper">
          <h6>{{ 'turbo.core.RentalDialog.Return' | translate : 'Return'}}</h6>
          <p>{{ booking.ReturnTime | date : 'd/M' }} - {{ booking.ReturnTime | date : 'HH:mm' }}</p>
        </div>
      </div>
    </div>
    <div class="container booking-summary-card">
      <div class="title-with-divider">
        <div class="title">{{ 'Intellitrailer.Booking.Components.BookingSummary.RentalLocation' | translate : 'Rental location'}}</div>
      </div>
      <div class="summary-content">
        <ng-container *ngIf="booking.ReturnLocation && booking.ReturnLocation.Id != booking.PickupLocation.Id">
          <span class="material-icons rent-times-divider" style="color: var(--action-secondary-background)">
            arrow_right_alt
          </span>
          <div class="rent-time-wrapper">
            <p>{{ booking.PickupLocation.Name }}</p>
            <p>{{ booking.PickupLocation.Address }}</p>
          </div>
          <div class="rent-time-wrapper rent-time-return-wrapper">
            <p>{{ booking.ReturnLocation.Name }}</p>
            <p>{{ booking.ReturnLocation.Address }}</p>
          </div>
        </ng-container>
        <div class="summary-content-row" *ngIf="!(booking.ReturnLocation && booking.ReturnLocation.Id != booking.PickupLocation.Id)">
          <p>{{ booking.PickupLocation.Name }}</p>
          <p>{{ booking.PickupLocation.Address }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md rounded-border-card-content">
    <div class="booking-summary-card container" *ngIf="shownAddons && shownAddons.length > 0">
      <div class="title-with-divider">
        <div class="title">{{ 'Intellitrailer.Booking.UnConfirmedBooking.Addons' | translate : 'Addons' }}</div>
      </div>
      <div class="summary-content">
        <ng-container *ngFor="let i of addons; let k = index">
          <div class="mb-2" *ngIf="!i.IsHidden && i.Title.indexOf('Depositum') == -1" [ngClass]="!i.IsSelected && booking?.Id ? 'line-through' : 'line-not-through'">
            <div class="row">
              <div class="col-7" style="margin-bottom:5px;">
                <p *ngIf="i.Title.indexOf('Turbo.') == -1" [ngStyle]="!i.IsSelected ? {'text-decoration': 'line-through'} : {}">{{ i.Title }}</p>
                <p *ngIf="i.Title.indexOf('Turbo.') == 0" [ngStyle]="!i.IsSelected ? {'text-decoration': 'line-through'} : {}">{{ i.Title | translate }}</p>
                <a style="text-decoration:underline" *ngIf="i.Price && i.IsSelected" (click)="addedProductChanged(i, false)">{{ 'Intellitrailer.Booking.UnConfirmedBooking.addonReadMore' | translate : 'Read more' }}</a>
                <a style="text-decoration:underline" *ngIf="i.Price && !i.IsSelected" (click)="addedProductChanged(i, true)">{{ 'Intellitrailer.Booking.UnConfirmedBooking.addonReadd' | translate : 'Add' }}</a>
              </div>
              <div class="col-5 text-right">
                <ng-container *ngIf="!i.Price">
                  {{ 'Intellitrailer.Booking.BookingSummary.Included' | translate : 'Included' }}
                </ng-container>
                <ng-container *ngIf="i.Price">
                  <span [ngStyle]="!i.IsSelected ? {'text-decoration': 'line-through'} : {}">
                    <!--<mat-checkbox class="checkOk" [checked]="i.IsSelected" (change)="addedProductChanged(i, $event)" [disabled]="confirmed">-->
                    {{ i.Price | currency }} / <span *ngIf="i.Period == 24!">{{ 'Intellitrailer.Booking.BookingSummary.Day' | translate : 'day' }}</span>
                    <span *ngIf="i.Period != 24">{{ i.Period }}h</span>
                    <!--</mat-checkbox>-->
                  </span>
                </ng-container>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col">
                <span (click)="togglAddonInfo(k)" class="toggle-addon-info-text" *ngIf="i.Description && i.Description.length > 0 && !showAddonInfo[k]"><strong> {{ 'Intellitrailer.Booking.UnConfirmedBooking.ShowAddonInfo' | translate : 'Show info' }} </strong></span>
                <span (click)="togglAddonInfo(k)" class="toggle-addon-info-text" *ngIf="i.Description && i.Description.length > 0 && showAddonInfo[k]"><strong> {{ 'Intellitrailer.Booking.UnConfirmedBooking.HideAddonInfo' | translate : 'Hide info' }} </strong></span>
                <span class="material-icons toggle-addon-info-icon" (click)="togglAddonInfo(k)" *ngIf="i.Description && i.Description.length > 0 && !showAddonInfo[k]">
                  info
                </span>
                <span class="material-icons toggle-addon-info-icon" (click)="togglAddonInfo(k)" *ngIf="i.Description && i.Description.length > 0 && showAddonInfo[k]">
                  cancel
                </span>
              </div>
            </div>
            <div class="row" [ngClass]="!showAddonInfo[k] ? 'addon-info-hidden' : 'addon-info-show'">
              <div class="col">
                <div class="addon-description" *ngIf="i.Description && i.Description.indexOf('Turbo.') == 0">{{ i.Description | translate }}</div>
                <div class="addon-description" *ngIf="i.Description && i.Description.indexOf('Turbo.') != 0">{{ i.Description }}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="booking-summary-card container">
      <div class="title-with-divider">
        <div class="title">{{ 'Intellitrailer.Booking.Component.BookingSummary.CalculatedPrice' | translate : 'Calculated price' }}</div>
      </div>
      <div class="summary-content">
        <div class="row" *ngIf="currentPrice.RentalPrice <= 0">
          <p class="col-9">{{ 'Intellitrailer.Booking.UnConfirmedBooking.FreeRent' | translate : 'Free rental period' }}</p>
          <p class="col-3 text-right"> {{ currentPrice.RentalPrice | currency }}</p>
        </div>
        <div class="row" *ngIf="currentPrice.RentalPrice > 0">
          <p class="col-9" [innerHTML]="rentalPeriodText"></p>
          <p class="col-3 text-right"> {{ currentPrice.RentalPrice | currency }}</p>
        </div>
        <div class="row" *ngIf="currentPrice.Discount">
          <p class="col-9" innerHTML="{{ 'turbo.core.RentalDialog.Discount' | translate : 'Discount'}}"></p>
          <p class="col-3 text-right"> {{ currentPrice.Discount | currency }}</p>
        </div>
        <ng-container *ngFor="let i of currentPrice.AddedProducts; let k = index">
          <div *ngIf="!i.ExcludeFromSum && !i.Hidden" class="row">
            <p *ngIf="i.Name.indexOf('Turbo.') == 0" class="col-9">{{ i.Name | translate }}</p>
            <p *ngIf="i.Name.indexOf('Turbo.') != 0" class="col-9">{{ i.Name }}</p>
            <p class="col-3 text-right">{{ i.OriginalPrice | currency }} </p>
          </div>
        </ng-container>
        <div class="row" *ngIf="currentPrice.BookingFee">
          <p class="col-9">
            {{ 'Intellitrailer.Booking.UnConfirmedBooking.BookingFee' | translate : 'BookingFee' }}
            <span class="material-icons toggle-addon-info-icon" (click)="showInfo('bookingFee')">
              info
            </span>
          </p>
          <p class="col-3 text-right"> {{ currentPrice.BookingFee | currency }}</p>
        </div>
        <div class="row">
          <p class="col-9">
            <strong>
            {{ 'Intellitrailer.Booking.UnConfirmedBooking.Total' | translate : 'Total' }}
            </strong>
          </p>
          <p class="col-3 text-right">
            <strong>
            {{ currentPrice.DiscountedTotalPrice | currency }}
            </strong>
          </p>
        </div>

        <ng-container *ngFor="let i of currentPrice.AddedProducts; let k = index">
          <div *ngIf="i.ExcludeFromSum && i.Price != 0" style="height: auto;">
            <span *ngIf="i.Name.indexOf('Turbo.') == 0" class="price-calculation-title">
              {{ i.Name | translate }}
              <span class="material-icons" *ngIf="addedPriceProds[k].Description" (click)="showInfo('info', i.Name, addedPriceProds[k].Description)">
                info
              </span>
            </span>
            <span *ngIf="i.Name.indexOf('Turbo.') != 0" class="price-calculation-title">
              {{ i.Name }}
              <span class="material-icons" *ngIf="addedPriceProds[k].Description" (click)="showInfo('info', i.Name, addedPriceProds[k].Description)">
                info
              </span>
            </span>
            <span class="price-calculation-value"> {{ i.Price | currency }} </span>
            <span *ngIf="i.Name.indexOf('Deposit') > -1" class="price-calculation-value-100">
              {{ 'Intellitrailer.Booking.Component.BookingSummary.DepositInfo' | translate : 'The deposit is refundable after the rent is completed.' }}
            </span>
          </div>
        </ng-container>
        <div class="summary-content-row" *ngIf="!showDiscount && !confirmed">
          <div class="link-wrapper">
            <a (click)="showDiscountCard()" [routerLink]="">{{ 'Intellitrailer.Booking.Component.BookingSummary.IHaveADiscount' | translate : 'I have a discount code' }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="booking-summary-card container" *ngIf="showDiscount">
      <div class="title-with-divider">
        <div class="title">{{ 'Intellitrailer.Booking.Component.BookingSummary.Discount' | translate : 'Discount'}}</div>
      </div>
      <div class="summary-content">
        <div class="discount-input-wrapper">
          <div *ngIf="!booking.DiscountId || booking.DiscountId.length == 0" class="mb-2">
            <mat-form-field appearance="outline" class="discount-input">
              <mat-label>{{ 'Intellitrailer.Booking.Component.BookingSummary.DiscountCode' | translate : 'Discount code'}}</mat-label>
              <input autocomplete="off" matInput [(ngModel)]="discountCode" [formControl]="discountControl">
              <mat-error *ngIf="incorrectDiscount">{{ 'Intellitrailer.Booking.Component.BookingSummary.InvalidDiscount' | translate : 'Invalid discount code'}}</mat-error>
            </mat-form-field>
          </div>
          <div class="mb-2" *ngIf="booking.DiscountId && booking.DiscountId.length > 0">
            <div>{{ 'Intellitrailer.Booking.Component.BookingSummary.DiscountCode' | translate : 'Discount code'}}: {{ booking.DiscountId }}</div>
            <div *ngIf="discount && discount.Type == 0">{{ 'Intellitrailer.Booking.Component.BookingSummary.Discount' | translate : 'Discount'}}: {{ discount.Value }}%</div>
            <div *ngIf="discount && discount.Type == 1">{{ 'Intellitrailer.Booking.Component.BookingSummary.Discount' | translate : 'Discount'}}: {{ discount.Value | currency }}</div>
            <div *ngIf="discount && discount.Comment">{{ 'Intellitrailer.Booking.Component.BookingSummary.Comment' | translate : 'Comment'}}: {{ discount.Comment }}</div>
          </div>
        </div>
        <div class="discount-button-wrapper">
          <button style="margin-left:0" *ngIf="booking.DiscountId && booking.DiscountId.length > 0" class="discount-button smaller remove-discount" mat-raised-button (click)="removeDiscount()">{{ 'Intellitrailer.Booking.Component.BookingSummary.Remove' | translate : 'Remove' }}</button>
          <button style="margin-left:0" *ngIf="!booking.DiscountId || booking.DiscountId.length == 0" class="discount-button smaller add-discount" mat-raised-button (click)="addDiscount()">{{ 'Intellitrailer.Booking.Component.BookingSummary.Save' | translate : 'Save' }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col rounded-border-card-content" *ngIf="!confirmed && rentalAgreement">
    <div class="title-with-divider">
      <div class="title">{{ 'Intellitrailer.Booking.Component.BookingSummary.Terms' | translate : 'Terms and conditions' }}</div>
    </div>
    <div class="summary-content">
      <div class="summary-content-row" *ngIf="false">
        <span [innerHTML]="rentalAgreement"></span>
      </div>
      <div class="summary-content-row">
        <p id="continue-to-agree">{{ 'Intellitrailer.Booking.Component.BookingSummary.TermsIAccept' | translate : 'By continuing I hereby accept the terms and conditions' }}</p>
        <div class="link-wrapper">
          <a (click)="showTerms()" [routerLink]="">{{ 'Intellitrailer.Rental.BookingSummary.ShowTerms' | translate : 'Show terms and conditions' }}</a> <span class="material-icons link-icon">chevron_right</span>
        </div>
      </div>
      <ng-container *ngIf="extraConditionExist">
        <div  class="summary-content-row" style="margin-top:25px; font-size:15px">
          <mat-checkbox [checked]="specialConditionChecked" (change)="sendSpecialConditionToParent($event)"> {{ 'Intellitrailer.Rental.BookingSummary.SpecialCondition_' + booking.PrimaryObjectTypeId | translate : '[Additional Condition]' }}</mat-checkbox>
        </div>
      </ng-container>
    </div>
  </div>
</div>

