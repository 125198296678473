<app-view-title Title="{{ 'Intellitrailer.Rental.ReturnComplete.Headline' | translate : 'Return complete!' }}" [isPrimaryColor]="true"></app-view-title>
<div *ngIf="theme != 'TysseRental'" class="view-content centered-view">
  <app-information-screen class="horizontal-vertical-center font-secondary-color"
                          primaryContent="{{ 'Intellitrailer.Rental.ReturnComplete.SuccessfulRentWithUs' | translate: 'You have successfully completed your rent! Thank you for choosing us.' }}"
                          image="/assets/Images/big-checkbox-white.png">


    <p>{{'Intellitrailer.Rental.ReturnComplete.msgSecondInfo' | translate : 'Your receipt will be sent to you by email. For future reference you can find this booking under the history tab on your page.'}}</p>
</app-information-screen>
</div>
<div *ngIf="theme == 'TysseRental'" class="view-content centered-view">
  <app-information-screen class="horizontal-vertical-center font-secondary-color"
                          primaryContent="{{ 'Intellitrailer.Rental.ReturnComplete.SuccessfulRentWithUs' | translate: 'You have successfully completed your rent! Thank you for choosing us.' }}"
                          image="/assets/Images/big-checkbox-black.png">


    <p>{{'Intellitrailer.Rental.ReturnComplete.msgSecondInfo' | translate : 'Your receipt will be sent to you by email. For future reference you can find this booking under the history tab on your page.'}}</p>
  </app-information-screen>
</div>
<div class="view-actions">
  <button mat-raised-button color="accent" (click)="confirm()">{{ 'Intellitrailer.Rental.ReturnComplete.cmdConfirm' | translate : 'OK' }}</button>
</div>

