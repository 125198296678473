import { EventEmitter, Injectable } from '@angular/core';
import { RentalInfo, ApiService, ReturnResultAction, BookingValidation, UIService, RequestDetails, ServiceRequestStatus, ServiceRequestTags, RentalObjectInfo, BookingErrorCode, PickupResultCode, ReturnResultCode, NotificationService, TurboHubEventType, PickupResult, RentalStatus, ProlongRentalResult, CacheService } from 't4core';
import { SecurityCheckModel } from '../../../lib/Models/SecurityCheckModel';
import { NavigationService, NavigationResolver } from '../NavigationService/navigation-service.service';
import * as moment_ from 'moment';
const moment = moment_;
import { AppSettingsService } from 't4-app-integration';

export class ActiveRental {
  public Id: number;
  public Rental: RentalInfo;
  public TimeStamp: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ActiveRentalService {
  private rentals: ActiveRental[] = [];
  private activeRental: ActiveRental;

  public onRentalUpdated: EventEmitter<number> = new EventEmitter<number>();

  public securityCheck = new SecurityCheckModel();
  private fetchSecurityCheck: boolean = true;

  //When the last time Unlock lock action have been called.
  private lastLockUpTime: number = 0;
  private cooldownLock: number = 30;

  constructor(private Api: ApiService, private UI: UIService, private appSettings: AppSettingsService, private notifications: NotificationService, private navigationService: NavigationService, private cache: CacheService) {
    // Populate from cache
    var cacheItem = this.cache.get("ARS_ActiveRentals");
    if (cacheItem) {
      this.rentals = cacheItem.data;

      // Try to refresh all from server
      this.rentals.map(x => this.refreshRental(x.Id));
    }

    var cacheItem = this.cache.get("ARS_ActiveRentalId");
    if (cacheItem) {
      this.setActiveRental(cacheItem.data);
    }

    // Subscribe to rental updates
    this.notifications.onEvent.subscribe(x => {
      if (x.Type == TurboHubEventType.Booking) {
        this.refreshRental(parseInt(x.EntityId), true);
      } 
    });
  }

  private updateCache() {
    this.cache.put("ARS_ActiveRentals", this.rentals, new Date(new Date().getTime() + (24 * 60 * 60 * 1000)));

    if (this.activeRental)
      this.cache.put("ARS_ActiveRentalId", this.activeRental.Id, new Date(new Date().getTime() + (168 * 60 * 60 * 1000)));
    else
      this.cache.del("ARS_ActiveRentalId");
  }

  // Set currently active rental
  public async setActiveRental(id: number) {
    // Is it already loaded?
    var rental = this.rentals.find(x => x.Rental.Id == id);

    // If not, load
    if (!rental) {
      var rentalInfo = await this.Api.get<RentalInfo>("/Rental/GetRentalInfo", { bookingId: id });
      rental = {
        Id: id,
        Rental: rentalInfo,
        TimeStamp: new Date()
      };

      this.rentals.push(rental);
      this.updateCache();
    } else {
      this.refreshRental(id, true);
    }

    this.activeRental = rental;
    this.updateCache();

    // Set local context
    if (rental.Rental.PickupLocationId) {
      this.appSettings.localLocationId = rental.Rental.PickupLocationId;
    }
  }

  // Update rental from database
  private async refreshRental(id: number, force: boolean = false) {
    var item = this.rentals.find(y => y.Id == id);
    if (item) {

      // If not a forced update, check i newer on server
      if (!force) {
        var hasChanged = await this.Api.get<RentalInfo>("/Data/HasChanged", { type: 'Rental', entityRef: id, version: item.Rental.RowVersion });
        if (!hasChanged) return;
      }
      
      var source = await this.Api.get<RentalInfo>("/Rental/GetRentalInfo", { bookingId: id });
      Object.keys(source).forEach((key) => {
        item.Rental[key] = source[key];
      })

      item.TimeStamp = new Date();
      this.updateCache();
      this.onRentalUpdated.emit(item.Id);
    }
  }

  public async getBooking(): Promise<RentalInfo> {
    if (!this.activeRental) {
      if (this.navigationService.getParam('rentalId')) {
        await this.setActiveRental(parseInt(this.navigationService.getParam('rentalId')));
      }
    }

    return this.activeRental.Rental;
  }

  // Try to start the rental
  public async startRental(): Promise<PickupResultCode> {
    var result = await this.Api.post<PickupResultCode>("/Rental/Disclose", null,
      {
        rentalId: this.activeRental.Id,
        bypassCardPayment: false,
        predisclose: false

      });

    if (result == PickupResultCode.SuccessWithPayment || result == PickupResultCode.SuccessWithoutPayment) {
      this.activeRental.Rental.Status = RentalStatus.Active;
      this.updateCache();
    }

    return result;
  }

  public async return(): Promise<ReturnResultCode> {
        var returnTime = new Date().toISOString();
        var result = await this.Api.post<ReturnResultCode>("/Rental/Return", null,
            {
                rentalId: this.activeRental.Id,
                returnTime: returnTime,
              returnLocation: this.activeRental.Rental.ReturnLocationId,
                receiptAction: ReturnResultAction.Confirm,
                bypassCardPayment: false,
                addons: [],
                extraReason: ""
            });

        if (result == ReturnResultCode.Success) {
          this.activeRental.Rental.Status = RentalStatus.Returned;
          this.updateCache();
         }

        return result;
  }

  /// Extend the active rental with a number of hours
  public async extend(returnTime: moment.Moment): Promise<ProlongRentalResult> {
    var rental = await this.getBooking();

    var extendToDate = returnTime.clone().toDate().toISOString();
    let ret = await this.Api.get<ProlongRentalResult>("Rental/ExtendToDate", { rentalId: rental.Id, extendTo: extendToDate });

    if (ret == ProlongRentalResult.Success) {
      rental.ReturnTime = returnTime.clone();
    }

    return ret;
  }

  public async getSecurityCheck(event: String): Promise<SecurityCheckModel> {
    var rental = await this.getBooking();
    if (this.fetchSecurityCheck && rental && rental.RentalObject.Id && rental.Id) {
            var token = this.UI.beginLoading("Intellitrailer.RentalService.ReturningObject", "Getting security check", null);
            var data = await this.Api.get<string>("/RentalObject/GetSecurityCheck",
                {
                  rentalObjectId: rental.RentalObject.Id,
                  rentalId: rental.Id,
                    isReturn: event == "Return" ? true : false
                });

            if (data) {
                var securityCheck = new SecurityCheckModel();
                var object = JSON.parse(data);

                securityCheck.Remarks = object.remarks;
                for (let checkPoint of object.securityChecks) {
                    if (securityCheck.CheckPoints.find(x => x.Key == checkPoint.key)) {
                        securityCheck.CheckPoints[securityCheck.CheckPoints.findIndex(x => x.Key == checkPoint.key)].Ok = checkPoint.ok;
                        securityCheck.CheckPoints[securityCheck.CheckPoints.findIndex(x => x.Key == checkPoint.key)].Failed = !checkPoint.ok;
                    }
                }
                this.UI.loaderCompleted(token);
                return securityCheck;
            }
        }
        this.fetchSecurityCheck = true;
        this.UI.loaderCompleted(token);
        return null;
    }

    public async saveSecurityCheck(data: SecurityCheckModel, event: String): Promise<boolean>  {
      var result = false
      var rental = await this.getBooking();
      if (rental && rental.RentalObject.Id && rental.Id && event != "FaultReport") {
            var token = this.UI.beginLoading("Intellitrailer.RentalService.ReturningObject", "Saving security check", null);

            var dataObject = {
                securityChecks: [],
                remarks: data.Remarks
            };

            for (let checkPoint of data.CheckPoints) {
                var jsonCheckPoint = { key: checkPoint.Key, ok: checkPoint.Ok };
                dataObject.securityChecks.push(jsonCheckPoint);
            }

            var jsonData = JSON.stringify(dataObject);

            result = await this.Api.get<boolean>("/RentalObject/SaveSecurityCheck",
                {
                  rentalObjectId: rental.RentalObject.Id,
                  rentalId: rental.Id,
                    jsonData: jsonData,
                    isReturn: event == "Return" ? true : false
                });

            this.UI.loaderCompleted(token);
        }


        else if (event == "FaultReport") {

            var aggregatedTags = ServiceRequestTags.None;

            if (data.CheckPoints.find(x => x.Key == "NoseWheel").Failed) aggregatedTags |= ServiceRequestTags.SupportWheel;
            if (data.CheckPoints.find(x => x.Key == "SecurityWire").Failed) aggregatedTags |= ServiceRequestTags.Wire;
            if (data.CheckPoints.find(x => x.Key == "Outside").Failed) aggregatedTags |= ServiceRequestTags.SupportWheel;
            if (data.CheckPoints.find(x => x.Key == "Inside").Failed) aggregatedTags |= ServiceRequestTags.SupportWheel;

            var request = await this.Api.post<RequestDetails>("/Service/CreateUserRequest", null, {
              rentalObjectId: rental.RentalObject.Id,
                description: data.Remarks,
                isInsurance: false,
                tags: aggregatedTags,
            });

            if (request && request.Status != ServiceRequestStatus.Invalid) {
                return true;
            }
            else {
                return false;
            }
        }
        return result;
    }

    public fetchSecurity(fetch: boolean) {
        this.fetchSecurityCheck = fetch;
    }

    public async hasLock(): Promise<boolean> {
        var booking = await this.getBooking();
        if (booking && booking.PickupLocation && booking.RentalObject && booking.RentalObject.Locks) {
            return true;
        }
        else {

          if (booking && booking.PickupLocation && booking.PickupLocation.RentalLocationGroupId == 115 || this.appSettings.contextId == 'KanLeiesUnmanned_API') {
                return true;
            }

            return false;
        }
  }

  // Cancel this booking
  public async cancel(): Promise<boolean> {
    var x = await this.UI.confirm("Intellitrailer.MyPage.BookingOverview.ConfirmBookingCancellation.Headline", "Confirm cancellation", "Intellitrailer.MyPage.BookingOverview.ConfirmBookingCancellation.Message", "Do you want to cancel this booking?");
    if (!x) return false;

      var token = this.UI.beginLoading("Intellitrailer.MyPaage.Overview.CancelBookingMsg", "Canceling booking", null);
      var result = await this.Api.delete<BookingErrorCode>("/Rental/Cancel",
        {
          booking: this.activeRental.Rental.Id
        });
      this.UI.loaderCompleted(token);
    if (result == BookingErrorCode.Valid) {
      this.activeRental.Rental.Status = RentalStatus.Canceled;
        this.UI.alert("Intellitrailer.MyPage.BookingOverview.CancelBookingSuccess.Title", "Cancelled!", "Intellitrailer.MyPage.BookingOverview.CancelBookingSuccess.Message", "The booking has been cancelled.");
        return true;
      }
      else {
        this.UI.alert("Turbo.Core.BookingLink.resendEmailFailed.Headline", "Failed!", "Intellitrailer.MyPage.BookingOverview.CancelBookingFailed.Message", "The booking could not be cancelled. Please try again.");
        return false;
      }
  }

  public storeWithRental(key: string, data: any, expires: Date) {
    this.cache.put(this.activeRental.Id + "_" + key, data, expires);
  }

  public getCached(key: string) {
    return this.cache.get(this.activeRental.Id + "_" + key);
  }


  public UpdateLastUnlock() {
    this.lastLockUpTime = Math.floor(Date.now() / 1000)
  }
  //Check if the last Unlock is older then cooldownLock(30s).
  public async checkAndUpdateLockTimer(): Promise<boolean> {
    let underLimit: boolean = false;
    if ((Math.floor(Date.now() / 1000) - this.lastLockUpTime < this.cooldownLock)) {
      underLimit = true;
    }
    else {
      underLimit = false;
    }
    this.UpdateLastUnlock();
    return underLimit;
  }


}
