<h3 class="text-center" mat-dialog-title>
  <span *ngIf="product.Title.indexOf('Turbo.') == 0"> {{ product.Title | translate }}</span>
  <span *ngIf="product.Title.indexOf('Turbo.') != 0"> {{ product.Title }}</span>
</h3>
<div mat-dialog-content>
  <div class="container">
    <div classs="row">
      <p style="font-size: 14px; font-weight: bold;">
        <span *ngIf="product.Description.indexOf('Turbo.') == 0"> {{ product.Description | translate }}</span>
        <span *ngIf="product.Description.indexOf('Turbo.') != 0"> {{ product.Description }}</span>
      </p>
      <p style="font-size: 14px; font-weight: bold;">
        {{ 'Intellitrailer.Booking.UnConfirmedBooking.ConfirmAddon' | translate : 'Do you want to continue with' }}
        <span *ngIf="product.Title.indexOf('Turbo.') == 0"> {{ product.Title | translate : 'Excess reduction' }}?</span>
        <span *ngIf="product.Title.indexOf('Turbo.') != 0"> {{ product.Title }}?</span>
      </p>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-raised-button color="primary" (click)="confirm()" style="width: 100%; margin-bottom: 10px;">{{ 'Intellitrailer.Booking.Dialog.FindLocationDialog_' + product.Id + '.Yes' | translate : 'Yes' }}</button>
  <button mat-button mat-raised-button color="accent" (click)="onNoClick()" style="width: 100%; margin-bottom: 10px; color: var(--action-background-color) !important; background-color: white !important; ">{{ 'Intellitrailer.Booking.Dialog.FindLocationDialog_' + product.Id + '.ContinueWithout' | translate : 'Continue without' }}</button>
</div>

