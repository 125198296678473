<app-view-title [isPrimaryColor]="true" Title="{{ 'Intellitrailer.Booking.Components.BookingSummary.Trailer' | translate : 'Trailer'}}: {{ booking?.RentalObject?.Title }}"></app-view-title>
<view-content [centered]="true" [fullscreen]="true">
  <app-btPermissionRequired *ngIf="!ignoreBTCheck && appSvc.Device.Bluetooth < 2"></app-btPermissionRequired>
  <app-locationPermissionRequired *ngIf="(ignoreBTCheck || appSvc.Device.Bluetooth >= 2) && !ignoreLocationCheck && appSvc.Device.Location < 2"></app-locationPermissionRequired>
  <ng-container *ngIf="(ignoreBTCheck || appSvc.Device.Bluetooth >= 2) && (ignoreLocationCheck || appSvc.Device.Location >= 2)">
    <ng-container *ngIf="!hasFailed && !hasCompleted">
      <app-card>
        <div class="spinner-container" *ngIf="count < 30">
          <mat-progress-spinner diameter="75" class="spinner" mode="determinate" value="{{count * (3 + 1.0/3.0)}}">
          </mat-progress-spinner>
          <h1 style="font-size: 24px">{{ 30 - count }}</h1>
        </div>
        <ng-container *ngIf="isSearching">
          <h2>{{ 'Intellitrailer.Rental.NFCNoLock.SearchingNFC' | translate : 'Searching for NFC tag' }}...</h2>
          <p>{{ 'Intellitrailer.Rental.NFCNoLock.UnlockingInfoNFC' | translate: 'Please scan the phone over NFC tag on the trailer.' }}</p>
          <div class="press-sensor-image"></div>
        </ng-container>
     </app-card>
    </ng-container>

    <ng-container *ngIf="!hasFailed && hasCompleted">
      <app-information-screen class="horizontal-vertical-center font-secondary-color"
                              title="{{ 'Intellitrailer.Rental.NFCLock.LockOpenTitle' | translate : 'The rental is started!' }}"
                              primaryContent="{{ 'Intellitrailer.Rental.NFCLock.LockOpenInstructions' | translate : 'You can start using the trailer now.' }}">
                              <!--secondaryContent="{{ 'Intellitrailer.Rental.NFCLock.CloseLockInstruction' | translate : '' }}"-->
      </app-information-screen>
    </ng-container>

    <app-card *ngIf="hasFailed && failReason == null">
      <h2>{{ 'Intellitrailer.Rental.NFCNoLock.TitleLockNotOpenNFC' | translate: 'Failed to identify NFC tag on the trailer' }}</h2>
      <div class="shake-lock-image"></div>
      <p>{{ 'Intellitrailer.Rental.NFCNoLock.NotFoundInfo2' | translate : 'Please make sure you are at the correct trailer.' }}</p>
      <p>{{ 'Intellitrailer.Rental.NFCNoLock.txtLockNotOpen2NFC' | translate: 'Please keep scanning the phone over the NFC tag on the trailer.'}}</p>
      <button mat-raised-button (click)="retry()">{{ 'Intellitrailer.Rental.NFCNoLock.Retry' | translate : 'Retry' }}</button>
    </app-card>

    <app-card *ngIf="hasFailed && failReason == 0">
      <h2>{{ 'Intellitrailer.Rental.NFCNoLock.headBluetoothError0' | translate : 'Bluetooth access denied' }}</h2>
      <p>{{ 'Intellitrailer.Rental.NFCNoLock.msgBluetoothError0' | translate : 'Access to bluetooth is required to be able to unlock the lock. Please grant the permissions in you phone settings. Or re-install the app and it will ask you for the permission again.' }}</p>
      <button mat-raised-button (click)="retry()">{{ 'Intellitrailer.Rental.NFCNoLock.Retry' | translate : 'Retry' }}</button>
      <button mat-raised-button color="accent" (click)="openSettings()" *ngIf="appSettings.meetsMinimumAppVersion(3.0)">{{ 'Intellitrailer.Rental.NFCNoLock.cmdPermissions' | translate : 'Open settings' }}</button>
    </app-card>

    <app-card *ngIf="hasFailed && failReason == 1">
      <h2>{{ 'Intellitrailer.Rental.NFCNoLock.headBluetoothError1' | translate : 'Bluetooth is not enabled' }}</h2>
      <p>{{ 'Intellitrailer.Rental.NFCNoLock.msgBluetoothError1' | translate : 'Access to bluetooth is required to be able to unlock the lock. Please enable bluetooth and try again.' }}</p>
      <button mat-raised-button (click)="retry()">{{ 'Intellitrailer.Rental.NFCNoLock.Retry' | translate : 'Retry' }}</button>
    </app-card>

    <app-card *ngIf="hasFailed && failReason == 10">
      <h2>{{ 'Intellitrailer.Rental.NFCNoLock.headLocationError0' | translate : 'Location access denied' }}</h2>
      <p>{{ 'Intellitrailer.Rental.NFCNoLock.msgLocationError0' | translate : 'Access to location is required to be able to unlock the lock. Please grant the permissions in you phone settings. Or re-install the app and it will ask you for the permission again.' }}</p>
      <button mat-raised-button (click)="retry()">{{ 'Intellitrailer.Rental.NFCNoLock.Retry' | translate : 'Retry' }}</button>
      <button mat-raised-button color="accent" (click)="openSettings()" *ngIf="appSettings.meetsMinimumAppVersion(3.0)">{{ 'Intellitrailer.Rental.NFCNoLock.cmdPermissions' | translate : 'Open settings' }}</button>
    </app-card>

    <app-card *ngIf="hasFailed && failReason == 20">
      <h2>{{ 'Intellitrailer.Rental.NFCNoLock.headLocationError1' | translate : 'Location services is not enabled' }}</h2>
      <p>{{ 'Intellitrailer.Rental.NFCNoLock.msgLocationError1' | translate : 'Access to Location is required to be able to unlock the lock. Please enable Location and try again.' }}</p>
      <button mat-raised-button (click)="retry()">{{ 'Intellitrailer.Rental.NFCNoLock.Retry' | translate : 'Retry' }}</button>
    </app-card>
  </ng-container>
</view-content>
<view-actions>
  <a (click)="skipBTCheck()" *ngIf="!ignoreBTCheck && appSvc.Device.Bluetooth < 2">{{ 'Intellitrailer.Rental.NFCNoLock.cmdSkip' | translate : 'Skip' }}</a>
  <a (click)="skipLocationCheck()" *ngIf="(ignoreBTCheck || appSvc.Device.Bluetooth >= 2) && !ignoreLocationCheck && appSvc.Device.Location < 2">{{ 'Intellitrailer.Rental.NFCNoLock.cmdSkip' | translate : 'Skip' }}</a>
  <button mat-raised-button color="accent" *ngIf="event != 'Active' && hasFailed" (click)="skip()">{{ 'Intellitrailer.Rental.NFCNoLock.cmdSkip' | translate : 'Skip' }}</button>
  <button mat-raised-button color="accent" *ngIf="!hasFailed && hasCompleted" (click)="nextStep()">{{ 'Intellitrailer.Navigation.Next' | translate : 'Next' }}</button>
</view-actions>

