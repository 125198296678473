import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogBase, ObjectTypeInfo, RentalObjectInfo } from 't4core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-addon-popup',
  templateUrl: './addon-popup.component.html',
  styleUrls: ['./addon-popup.component.css']
})
export class AddonPopupComponent extends DialogBase implements OnInit {

  public product: ObjectTypeInfo = new ObjectTypeInfo();

  constructor(
    public dialogRef: MatDialogRef<AddonPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { product: ObjectTypeInfo }) {
    super("AddonPopupComponent");

    if (this.data.product) this.product = this.data.product;
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public confirm() {
    this.dialogRef.close(true);
  }

  async ngOnInit() {
  }
}
