import { Component, HostListener, isDevMode, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BookingService, NavigationService } from 'app-components';
import { AuthService, LocalStorage, SettingsService, T4Environment, UIService } from 't4core';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { LayoutService } from 'app-components';
import { fader } from './route-animations';
import { MatDialog } from '@angular/material/dialog';
import { AppIntegrationService, AppSettingsService } from 't4-app-integration';
import { TranslationService } from 't4core';

@Component({
  selector: 'app-root[osk-root]',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = "app";
  public menuToggle = false;
  public menuComponentToggle = false;
  public firstClick = true;
  public isShowMenu: boolean = true; 
  public pageHeight: number;
  public viewHeight: number;
  public hideBackButton: boolean = false;
  public load: string = "loaded";
  public showCancelLoad: boolean = false;
  public openDialogCounter: number = 0;
  public isApp: boolean;
  public isStarting: boolean = true;
  private height: number = 0;

  public showTestMessage: boolean = true;
  constructor(
  
    public t: Title,
    private navigation: NavigationService,
    public appSettings: AppSettingsService,
    public UI: UIService,
    public dialog: MatDialog,
    public layout: LayoutService,
    public appSvc: AppIntegrationService,
    private bookingSvc: BookingService,
    private settings: SettingsService,
    private auth: AuthService,
    private renderer: Renderer2,
private translate: TranslationService  )
  {
    this.auth.clearTokens(new Date(2023, 4, 5));

    // Initialize
    this.appSettings.authenticateContext().then(x => {
      this.bookingSvc.getLocations();
      this.navigation.init();

      if (this.appSettings.getParam("language")) {
        this.appSettings.setLanguage(this.appSettings.getParam("language"));
        this.translate.setPreferedLanguage(this.appSettings.getParam("language"));
        this.translate.changeLanguage(this.appSettings.getParam("language"));
      }
      else {
        //If we dont have a language in the parameters, we then check if we have a language set in localstorage.
        var savedLanguage = this.appSettings.getLanguage();
        if (savedLanguage) this.translate.setPreferedLanguage(savedLanguage);
      }
      

      if (this.appSettings.getParam("inStore")) {
        this.bookingSvc.inStore = this.appSettings.getParam("inStore") == "true";
      }

      this.translate.load();

      if (this.appSettings.appType == 'app' && this.appSettings.contextId == "KanLeiesUnmanned_API") {
        //this.layout.loadJsScript(renderer, "https://static.zdassets.com/ekr/snippet.js?key=fa045ccb-5bab-4193-bff5-7ec511bf2836", "ze-snippet");
      }
    });

    this.appSettings.getClientVersion(true).then(x => {
      setInterval(() => appSettings.getClientVersion().then(x => {
        if(!x[1]) navigation.update();
      }), 1800000);
    });
    
    this.isShowMenu = appSettings.appType === 'app';
    this.isApp = this.appSettings.appType === 'app';
  }

  public async ngOnInit() {
    this.appSvc.notifyLoadingComplete();

    this.pageHeight = window.screen.height;
    this.viewHeight = window.innerHeight;

    this.isStarting = false; 
    if (!this.isApp) {
      // prevent bugs later on, mostly likely only while developing
      //this.appSettings.clearUser();
      this.appSettings.setUser(null);
    }

    this.setupHeightMessage();
    //if (this.isApp && !this.appSettings.meetsMinimumAppVersion(3.0)) {
    //  this.UI.alert("Intellitrailer.headNewAppVersion", "Outdated version", "Intellitrailer.msgNewAppVersion", "We have detected that you are using an outdated version of this app. Please visit your app store and check updates to avoid issues.");
    //}
  }

  public back() {
    if (this.hideBackButton) return;

    this.hideBackButton = true;
    this.navigation.back();
    this.layout.clear();

    setTimeout(() => {
      this.hideBackButton = false;
    },
      500);
  }

  public showMenu() {
    this.appSvc.requestDeviceState();
    this.menuComponentToggle = !this.menuComponentToggle;

    if (!this.menuToggle || this.firstClick) {
      this.menuToggle = !this.menuToggle;
      this.firstClick = false;
    }

    else {
      setTimeout(() => {
        this.menuToggle = !this.menuToggle;
      },
        100);

    }
  }

  public displayCancelLoad() {
    this.showCancelLoad = true;
  }

  public cancelLoad() {
    this.showCancelLoad = false;
  }

  prepareRoute(outlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  private setupHeightMessage() {
    if (this.isApp) return;
    const message = () => {
      //let contentHeight = document.querySelector('.view-content') !== null ? document.querySelector('.view-content').clientHeight : 768;
      let contentView = document.querySelector('.view-content');

      let contentHeight = 768;
      if (contentView !== null) {
        contentHeight = contentView.clientHeight;
      }
      if (contentHeight === this.height) return;

      //let headerHeight = document.querySelector('.view-content') !== null ? document.querySelector('.head').clientHeight : 0;
      let headerHeight = 0;
      let headAction = document.querySelector('.head');
      if (headAction !== null) {
        headerHeight = headAction.clientHeight;
      }


      //let footerHeight = document.querySelector('.view-actions') != null ? document.querySelector('.view-actions').clientHeight : 0;
      let footerHeight = 0;
      let contentAction = document.querySelector('.view-actions');
      if (contentAction !== null) {
          footerHeight = contentAction.clientHeight;
      }

      this.height = contentHeight;
      parent.postMessage(headerHeight + contentHeight + footerHeight, '*');
    };
    setInterval(message, 100);
  }

  public hideTestMessage() {
    this.showTestMessage = false;
    setTimeout(() => this.showTestMessage = true, 5000);
  }
}
