<h2 *ngIf="false">{{distance}}m</h2>
<ng-container *ngIf="booking?.Status == 0 || booking?.Status == 50">
  <ng-container *ngIf="toFarAway">
    <div class="rental-box-type-title" *ngIf="booking">
      {{ booking.PickupLocation.Name }}
    </div>

    <div #gmap id="map" style=" height: 400px; width: calc(100%+60px); position: relative; overflow: hidden; margin-left: -30px; margin-right: -30px;"></div>

  </ng-container>
  <ng-container *ngIf="!toFarAway">
    <div class="rental-box-type-title" *ngIf="booking">
      {{ 'Intellitrailer.Booking.Components.BookingSummary.Trailer' | translate : 'Trailer'}}: {{ booking.RentalObject?.Title }}
    </div>

    <div *ngIf="booking" class="rental-box-type-image" style="background-image: url({{booking.PrimaryType.PictureUri}})"></div>

    <div class="rental-times-wrapper">
      <div class="rental-box-title">{{ 'Intellitrailer.Booking.Components.BookingSummary.RentalTimes' | translate : 'Rent times'}}</div>
      <div class="rental-times-content">
        <span class="material-icons rent-times-divider">
          arrow_right_alt
        </span>
        <div class="rent-time-wrapper">
          <span class="rent-time-title">{{ 'turbo.core.RentalDialog.Pickup' | translate : 'Pickup'}}</span>
          <span class="rent-time">{{ booking.PickupTime | date : 'd/M' }} - {{ booking.PickupTime | date : 'HH:mm' }}</span>
        </div>
        <div class="rent-time-wrapper rent-time-return-wrapper">
          <span class="rent-time-title">{{ 'turbo.core.RentalDialog.Return' | translate : 'Return'}}</span>
          <span class="rent-time">{{ booking.ReturnTime | date : 'd/M' }} - {{ booking.ReturnTime | date : 'HH:mm' }}</span>
        </div>
      </div>
    </div>

    <div class="rental-box-title mt-4">{{ 'Intellitrailer.Booking.Components.BookingSummary.RentalLocation' | translate : 'Rental location'}}</div>
    <div class="rental-box-value">{{ booking.PickupLocation.Name }}</div>
  </ng-container>

  <ng-container *ngIf="toEarly || toFarAway">
    <span class="mt-4 tofaraway-pickup" *ngIf="toFarAway">{{ 'Intellitrailer.Rental.BookingInfo.GoToLocationPrompt' | translate : 'Go to {0} to start your rent' : [booking.PickupLocation?.Name] }}</span>
    <span class="mt-4 tofaraway-pickup" *ngIf="!toFarAway && toEarly">
      {{ 'Intellitrailer.Rental.BookingInfo.ReadyForPickup' | translate : 'Ready for pickup' }} {{ pCountdown.locale(Settings.clientSettings.Language).humanize(true)  }}

      <button *ngIf="available && toEarly" (click)="pickupNow()" mat-button mat-raised-button color="primary" style="margin-bottom: 10px;">{{ 'Intellitrailer.Booking.Components.BookingSummary.StartNow' | translate : 'Start Now'}}</button>
    </span>

  </ng-container>
  <ng-container *ngIf="!(toEarly || toFarAway)">
    <button class="mt-4" *ngIf="hasLock" (click)="sendRentalAction('start')" mat-raised-button color="primary">{{ 'Intellitrailer.Rental.Components.RentalBox.StartRent' | translate : 'Start rent' }}</button>
    <span class="mt-4 tofaraway-pickup" *ngIf="!hasLock">{{ 'Intellitrailer.Rental.BookingInfo.EnterStoreInfo' | translate : 'Enter the store to sign the rental contract and get access to keys to the vehicle' }}</span>
  </ng-container>

  <button id="cancel" (click)="sendRentalAction('cancelBooking')" mat-button>{{ 'Intellitrailer.MyPage.BookingOverview.CancelBooking' | translate : 'Cancel booking' }}</button>
</ng-container>

  <ngContainer *ngIf="booking?.Status == 100 || booking?.Status == 150">
    <div class="rental-box-type-title" *ngIf="booking">
      {{ 'Intellitrailer.Booking.Components.BookingSummary.Trailer' | translate : 'Trailer'}}: {{ booking.RentalObject?.Title }}
    </div>

    <div *ngIf="booking" class="rental-box-type-image" style="background-image: url({{booking.PrimaryType.PictureUri}})"></div>

    <div class="rental-box-title mt-4">{{ 'Intellitrailer.Rental.Components.RentalBox.ReturnTo' | translate : 'Return to'}}</div>
    <div class="rental-box-value">{{ booking.PickupLocation.Name }}</div>

    <div class="rental-box-title mt-3" *ngIf="!late">{{ 'Intellitrailer.Rental.InFlight.TimeLeft' | translate : 'TimeLeft' }}</div>
    <div class="rental-box-title mt-30" style="color: rgb(255, 13, 13)" *ngIf="late">{{ 'Intellitrailer.Rental.InFlight.Delayed' | translate : 'Delayed' }}</div>
    <div class="rental-box-value" style="color: rgb(255, 13, 13)">{{ rCountdown?.locale(Settings.clientSettings.Language).humanize() }} ({{ booking.ReturnTime | date : 'd/M' }} - {{ booking.ReturnTime | date : 'HH:mm' }})</div>

    <div class="rental-box-active-actions-wrapper mt-4">
      <div class="rental-box-active-action" *ngIf="hasLock">
        <button class="cmdUnlock" mat-raised-button color="primary" (click)="sendRentalAction('unlock')"><mat-icon>lock_open</mat-icon></button>
        <div class="rental-box-active-action-expl">
          {{ 'Intellitrailer.Rental.InFlight.Unlock' | translate : 'Unlock' }}
        </div>
      </div>
      <div class="rental-box-active-action" *ngIf="!late && !isVarebil">
        <button class="cmdUnlock" mat-raised-button color="primary" (click)="sendRentalAction('extend')"><mat-icon>update</mat-icon></button>
        <div class="rental-box-active-action-expl">
          {{ 'Intellitrailer.Rental.InFlight.Extend' | translate : 'Extend' }}
        </div>
      </div>

      <span class="mt-4 tofaraway-pickup" *ngIf="toFarAway">{{ 'Intellitrailer.Rental.BookingInfo.ReturnGoToLocationPrompt' | translate : 'Go to {0} to return your trailer' : [booking.ReturnLocation?.Name] }}</span>
      <span class="mt-4 tofaraway-pickup" *ngIf="!toFarAway && !hasLock">{{ 'Intellitrailer.Rental.BookingInfo.EnterStoreInfoReturn' | translate : 'Enter the store to complete the return process and return the keys to the vehicle' }}</span>
      <button class="mt-4 no-button" *ngIf="!toFarAway && hasLock" mat-raised-button (click)="sendRentalAction('return')">{{ 'Intellitrailer.Rental.InFlight.TerminateRent' | translate : 'Terminate rent' }}</button>
    </div>
  </ngContainer>
  
